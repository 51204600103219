export const PRIORITIES = {
  LOW: "low",
  MODERATE: "moderate",
  SEVERE: "severe",
  CRITICAL: "critical",
  SUBSTANTIAL: "substantial",
};

export const RESOLUTION_STATUS = {
  TODO: "todo",
  IN_PROGRESS: "in_progress",
  IN_REVIEW: "in_review",
  DONE: "done",
  CANCELLED: "cancelled",
};
