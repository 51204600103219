<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="headline">
        Mark device
        {{ deviceName }}
        as {{ markAs }}
        <v-spacer />
        <v-btn icon @click="show = null">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="value === 'found-device'" class="mt-4">
        You are about to mark this missing device as found. If this device is
        healthy, it will be returned to Available Inventory and become
        assignable. Are you sure?
      </v-card-text>
      <v-card-text v-else class="mt-4">
        You are about to mark this device as missing. If this device is healthy,
        it will be removed from Available Inventory and placed in Unavailable
        Inventory. Are you sure?
        <v-text-field
          v-model="formattedDateReportedMissing"
          label="Date Reported Missing"
          disabled
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="show = null"> Cancel </v-btn>
        <v-btn color="primary" text @click="updateDevice"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useAlerts from "@tod-ui/composables/useAlerts";
import { computed, ref } from "vue";
import useDevice from "../useDevice";
import { formatDate, currentDate } from "@tod-ui/helpers/datetime";

export default {
  name: "DialogUpdateDevice",
  props: {
    device: {
      type: Object,
      required: true,
    },
    value: {
      validator: (val) =>
        val === null || (typeof val === "string" && val.length > 0),
      default: null,
    },
  },
  setup(props, { emit }) {
    const { updateFacilityDeviceDisposition, deviceName } = useDevice(
      props.device
    );
    const markAs = computed(() =>
      props.value === "found-device" ? "Found" : "Missing"
    );
    const show = computed({
      get: () =>
        props.value === "found-device" || props.value === "missing-device",
      set: (value) => emit("input", value ? props.value : null),
    });
    const { addAlert } = useAlerts();

    const dateReportedMissing = ref(currentDate());

    const formattedDateReportedMissing = computed(() => {
      return formatDate(dateReportedMissing.value);
    });

    return {
      updateFacilityDeviceDisposition,
      deviceName,
      markAs,
      show,
      addAlert,
      dateReportedMissing,
      formattedDateReportedMissing,
    };
  },
  methods: {
    async updateDevice() {
      if (!this.value) return;

      const dateReportedMissing = this.dateReportedMissing;

      try {
        const successMessage = await this.updateFacilityDeviceDisposition(
          this.$apollo,
          this.value === "found-device" ? "in_service" : "missing",
          dateReportedMissing
        );
        if (successMessage) {
          this.addAlert({
            type: "success",
            message: successMessage,
            timeout: 10,
          });
        }
      } catch (errorMessage) {
        this.addAlert({
          type: "error",
          message: errorMessage,
          timeout: 15,
        });
      }
      this.show = false;
    },
  },
};
</script>
