<template>
  <v-skeleton-loader
    :loading="loading"
    type="table-thead, table-row-divider@10"
  >
    <v-data-table
      tile
      class="table-striped"
      :headers="headers"
      :items="inventory"
      :custom-sort="customSort"
      :custom-filter="customFilter"
      :search="search"
      :sort-by="['devicesName']"
      :items-per-page.sync="tableState.itemsPerPage"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
    >
      <template #item.devicesName="{ item: { devicesName } }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="(device, index) in devicesName"
            :key="index"
            class="ml-0 pl-0"
          >
            <v-list-item-content>{{ device }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.devicesHealth="{ item: { devices } }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="device in devices"
            :key="device.id"
            class="ml-0 pl-0"
          >
            <v-list-item-content class="pa-0">
              <DeviceGaugeCluster
                :device="device"
                class="device-details flex-grow-0 non-export mr-1"
              />
              <div class="device-details">
                <v-tooltip bottom v-if="device.disposition === 'missing'">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="hidden-md-and-down d-inline"
                    >
                      {{ titleCase(device.disposition) }}
                    </span>
                  </template>
                  <span>
                    Date Reported Missing:
                    <template v-if="device.dateReportedMissing">
                      <span>{{
                        formattedDateReportedMissing(device.dateReportedMissing)
                      }}</span>
                    </template>
                    <template v-else>Unknown</template>
                  </span>
                </v-tooltip>
                <span
                  v-else-if="device.rmaRequest"
                  class="hidden-md-and-down d-inline"
                >
                  {{ rmaRequestText(device.rmaRequest) }}
                </span>
                <span
                  v-else-if="device.disposition != 'in_service'"
                  class="hidden-md-and-down d-inline"
                >
                  {{ titleCase(device.disposition) }}
                </span>
                <span v-else class="hidden-md-and-down d-inline">
                  Lost Contact
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.devicesLastMotion="{ item: { devicesLastMotion } }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="(lastMotion, index) in devicesLastMotion"
            :key="index"
            class="ml-0 pl-0"
          >
            <v-list-item-content>
              {{ lastMotion }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template
        #item.devicesLastAssignedTo="{
          item: { devicesLastAssignedTo, devices },
        }"
      >
        <v-list dense color="transparent">
          <v-list-item
            v-for="(lastAssignedTo, index) in devicesLastAssignedTo"
            :key="index"
            class="ml-0 pl-0"
          >
            <v-list-item-icon class="action-btn mr-4">
              <DialogAssignmentHistory :device="devices[index]" />
            </v-list-item-icon>
            <v-list-item-content> {{ lastAssignedTo }} </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.manage="{ item: { type, devices, name } }">
        <MenuManageDevice
          v-for="device in devices"
          :key="device.id"
          :device="device"
          :unit-name="type === 'unit' ? name : undefined"
          v-on="$listeners"
        />
      </template>

      <template #footer.prepend>
        <ButtonExportCSV
          class="order-last"
          :table-data="inventory"
          :headers="headers"
          filename="SD_Inventory_Unavailable"
        />
        <ButtonPrint
          class="order-last"
          :before-print="showAllItems"
          :after-print="restoreItemsPerPage"
        />
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import DialogAssignmentHistory from "./DialogAssignmentHistory.vue";
import MenuManageDevice from "./MenuManageDevice.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";

import { stringSortCollator, titleCase } from "@tod-ui/helpers/strings";
import useDataTable from "@components/common/useDataTable";
import useInventory from "../useInventory";
import { shouldShowDisposition } from "@components/common/helpers/devices";
import { rmaRequestText } from "@tod-ui/helpers/devices";
import { formatDate } from "@tod-ui/helpers/datetime";

export default {
  name: "InventoryUnavailableTable",
  components: {
    DeviceGaugeCluster,
    DialogAssignmentHistory,
    ButtonPrint,
    ButtonExportCSV,
    MenuManageDevice,
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { loading, unavailableInventory } = useInventory();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
    });
    return {
      loading,
      inventory: unavailableInventory,
      tableState,
      showAllItems,
      restoreItemsPerPage,
    };
  },
  computed: {
    formattedDateReportedMissing() {
      return (date) => {
        if (date) {
          return formatDate(date);
        } else {
          return "Unknown";
        }
      };
    },
    headers() {
      return [
        {
          text: "Smart Unit #",
          value: "name",
          width: "10%",
        },
        {
          text: "Device #",
          value: "devicesName",
          width: "10%",
        },
        {
          text: "Device Status",
          value: "devicesHealth",
          width: "30%",
          toString: (device) =>
            device.disposition != "in_service"
              ? titleCase(device.disposition)
              : "Lost Contact",
        },
        {
          text: "Last Motion",
          value: "devicesLastMotion",
          width: "20%",
          class: "text-no-wrap",
        },
        {
          text: "Last Assigned Unit",
          value: "devicesLastAssignedTo",
          width: "20%",
          class: "text-no-wrap",
          sortable: false,
        },
        {
          text: "Manage Device",
          value: "manage",
          width: "1%",
          class: "action-column",
          cellClass: "action-column",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      let _search = search.toLowerCase();

      // Filter on `device`
      if (
        item.devicesName.find((d) => d.toLowerCase().indexOf(_search) >= 0) !==
        undefined
      )
        return true;

      // Filter on lastAssigned
      if (
        item.devicesLastAssignedTo.find(
          (d) => d.toLowerCase().indexOf(_search) >= 0
        ) !== undefined
      )
        return true;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;
      items.sort((a, b) => {
        if (_sortBy === "devicesName") {
          const aDevices = a.devicesName
            .sort((da, db) => stringSortCollator.compare(da, db) * _sortDesc)
            .join(" ");
          const bDevices = b.devicesName
            .sort((da, db) => stringSortCollator.compare(da, db) * _sortDesc)
            .join(" ");

          return stringSortCollator.compare(aDevices, bDevices) * _sortDesc;
        } else if (_sortBy === "devicesLastMotion") {
          var Aname = "";
          var Bname = "";
          var AextContactDate = a.devices[0].motionStartedAt;
          if (a.devices.length > 1) {
            for (const dev in a.devices) {
              if (a.devices[dev].motionStartedAt > AextContactDate) {
                if (_sortDesc < 0) {
                  Aname = a.devices[dev].shortId || a.devices[dev].externalId;
                }
                AextContactDate =
                  _sortDesc < 0
                    ? a.devices[dev].motionStartedAt
                    : AextContactDate;
              } else {
                AextContactDate =
                  _sortDesc > 0
                    ? a.devices[dev].motionStartedAt
                    : AextContactDate;
                if (_sortDesc > 0) {
                  Aname = a.devices[dev].shortId || a.devices[dev].externalId;
                }
              }
            }
          } else {
            Aname = a.devices[0].shortId || a.devices[0].externalId;
          }
          var BextContactDate = b.devices[0].motionStartedAt;
          if (b.devices.length > 1) {
            for (const dev in b.devices) {
              if (b.devices[dev].motionStartedAt > BextContactDate) {
                BextContactDate = _sortDesc
                  ? b.devices[dev].motionStartedAt
                  : BextContactDate;
                if (_sortDesc) {
                  Bname = b.devices[dev].shortId || b.devices[dev].externalId;
                }
              } else {
                BextContactDate = !_sortDesc
                  ? b.devices[dev].motionStartedAt
                  : BextContactDate;
                if (!_sortDesc) {
                  Bname = b.devices[dev].shortId || b.devices[dev].externalId;
                }
              }
            }
          } else {
            Bname = b.devices[0].shortId || b.devices[0].externalId;
          }
          AextContactDate =
            AextContactDate == null
              ? "1970-01-01T00:00:00.000000Z"
              : AextContactDate;
          BextContactDate =
            BextContactDate == null
              ? "1970-01-01T00:00:00.000000Z"
              : BextContactDate;

          return (BextContactDate < AextContactDate ? -1 : 1) * _sortDesc;
        } else if (_sortBy === "devicesHealth") {
          const aDispositions = a.devices.map((dev) => dev.disposition).sort();
          const aDisposition =
            _sortDesc < 0 ? aDispositions.pop() : aDispositions[0];

          const bDispositions = b.devices.map((dev) => dev.disposition).sort();
          const bDisposition =
            _sortDesc < 0 ? bDispositions.pop() : bDispositions[0];

          return (
            stringSortCollator.compare(aDisposition, bDisposition) * _sortDesc
          );
        } else {
          // Default sort comparison
          if (typeof a[_sortBy] === "string") {
            return b[_sortBy] === " - "
              ? -1
              : stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc;
          }
          return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
        }
      });

      return items;
    },
    titleCase,
    shouldShowDisposition,
    rmaRequestText,
  },
};
</script>

<style scoped>
.device-details {
  flex: 1 1 auto;
}
</style>
