<template>
  <LayoutPage :title="['Management Summary', 'Monthly Trends']">
    <v-row>
      <v-col>
        <v-select
          v-model="selectedRegions"
          :items="['Three by Three Storage', 'East Region', 'West Region']"
          label="Select Regions"
          item-text="name"
          item-value="id"
          multiple
          dense
          outlined
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="text-truncate">{{ item }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedRegions.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedFacilities"
          :items="[
            '3x3 Self Storage - Denton',
            '3x3 Self Storage - Fort Worth',
            '3x3 Self Storage - Waco',
            '3x3 Self Storage - lewisville',
            '3x3 Self Storage - Abllene',
          ]"
          label="Select Facilities"
          multiple
          clearable
          item-text="name"
          item-value="id"
          dense
          outlined
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="text-truncate">{{ item }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedFacilities.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          v-model="selectedTableName"
          :items="tableNames"
          item-text="name"
          dense
          outlined
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedTrends"
          :items="trends"
          item-text="name"
          dense
          outlined
        />
      </v-col>
    </v-row>
    <div class="table-container">
      <v-data-table
        :headers="conversionTable.headers"
        :items="conversionTable.body"
        :items-per-page="5"
        class="elevation-1"
      >
        <template #body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.facilityName">
              <template v-for="(value, key) in item">
                <CustomTableCell
                  :key="key"
                  :value="value"
                  :is-highlighted="isHighlighted(key)"
                  :is-highlight-color="true"
                  :unit="getUnit(key)"
                />
              </template>
            </tr>
            <tr class="total">
              <td :class="{ highlighted: isHighlighted('total') }">Total</td>
              <td
                ::class="{ highlighted: isHighlighted('currentMonthRevenue') }"
              >
                $3,828
              </td>
              <td :class="{ highlighted: isHighlighted('total') }"></td>
              <template v-if="selectedTrends === 'byTrends'">
                <td
                  :class="{
                    highlighted: isHighlighted('facilityPenetrations'),
                  }"
                ></td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CustomTableCell from "./CustomTableCell.vue";
import { facilityData } from "./data";
import useDashboard from "./useDashboard";
import { useRoute } from "vue-router/composables";
import { computed, ref, watch, watchEffect } from "vue";

export default {
  name: "ConversionTable",
  components: {
    LayoutPage,
    CustomTableCell,
  },
  setup() {
    const { selectedFacilityIds, filteredFacilities, dashboardData } =
      useDashboard();

    const route = useRoute();

    const highlightCol = computed(() => {
      return route.params.highlightColumn;
    });
    const highlightColumn = computed(() => {
      if (highlightCol.value === "august2024") {
        return "june2024";
      } else if (highlightCol.value === "september2023") {
        return "july2023";
      }
      return "mtdrevenue";
    });

    const isHighlighted = (column) => {
      if (highlightColumn.value === "mtdrevenue") {
        return ["currentMonthRevenue", "totalRevenue"].includes(column);
      }
      return column === highlightColumn.value;
    };

    const getColor = (column, value) => {
      return "rgba(10,1,131,0.8)";
    };

    const selectedPeriod = ref("last12Months");
    const selectedTrends = ref("byMonths");

    const conversionTable = computed(() => {
      // Determine which columns to show based on the selected period
      let headersToShow = [
        { text: "Facility Name", value: "facilityName" },
        {
          text: "Current Month ($)",
          value: "currentMonthRevenue",
        },
      ];

      if (selectedTrends.value === "byTrends") {
        headersToShow.push(
          ...[
            { text: "vs. August 2024 (%)", value: "lastMonthRevenue" },
            {
              text: "vs. September 2023 (%)",
              value: "previousMonthRevenue",
            },
            {
              text: "3-Month Rolling Average (%)",
              value: "threeMonthsRollingAverage",
            },
          ]
        );
      }

      if (selectedPeriod.value === "last12Months") {
        headersToShow.push(
          ...[
            { text: "vs. August 2024 (%)", value: "june2024" },
            { text: "vs. July 2024 (%)", value: "may2024" },
            { text: "vs. June 2024 (%)", value: "april2024" },
            { text: "vs. May 2024 (%)", value: "march2024" },
            { text: "vs. April 2024 (%)", value: "february2024" },
            { text: "vs. March 2024 (%)", value: "january2024" },
            { text: "vs. February 2024 (%)", value: "december2023" },
            { text: "vs. January 2024 (%)", value: "november2023" },
            { text: "vs. December 2023 (%)", value: "october2023" },
            { text: "vs. November 2023 (%)", value: "september2023" },
            { text: "vs. October 2023 (%)", value: "august2023" },
            { text: "vs. September 2023 (%)", value: "previousMonthRevenue" },
          ]
        );
      }

      return {
        headers: headersToShow,
        body: dashboardData.value.map((facilityData) => {
          let rowData = {
            facilityName: facilityData.name,
            currentMonthRevenue: facilityData.currentMonthRevenue,
          };
          if (selectedTrends.value === "byTrends") {
            Object.assign(rowData, {
              lastMonthRevenue: facilityData.may2024,
              previousMonthRevenue: facilityData.previousMonthRevenue,
              threeMonthsRollingAverage:
                facilityData.threeMonthsRollingAverageRevenue,
            });
          }
          if (selectedPeriod.value === "last12Months") {
            Object.assign(rowData, {
              june2024: facilityData.june2024,
              may2024: facilityData.may2024,
              april2024: facilityData.april2024,
              march2024: facilityData.march2024,
              february2024: facilityData.february2024,
              january2024: facilityData.january2024,
              december2023: facilityData.december2023,
              november2023: facilityData.november2023,
              october2023: facilityData.october2023,
              september2023: facilityData.september2023,
              august2023: facilityData.august2023,
              july2023: facilityData.july2023,
            });
          }

          return rowData;
        }),
      };
    });

    watchEffect(() => {
      if (highlightColumn.value === "mtdrevenue") {
        selectedTrends.value = "byTrends";
        selectedPeriod.value = "customPeriod";
      }
    });

    watch(selectedTrends, (value) => {
      if (value === "byTrends") {
        selectedPeriod.value = "customPeriod";
      } else if (value === "byMonths") {
        selectedPeriod.value = "last12Months";
      }
    });

    const getUnit = (column) => {
      if (
        column === "currentMonthRevenue" ||
        column === "threeMonthsRollingAverage"
      ) {
        return "$";
      }
      const columnsWithPercentage = [
        "lastMonthRevenue",
        "previousMonthRevenue",
        "lastMonthConversion",
        "previousMonthConversion",
        "june2024",
        "may2024",
        "april2024",
        "march2024",
        "february2024",
        "january2024",
        "december2023",
        "november2023",
        "october2023",
        "september2023",
        "august2023",
        "july2023",
      ];
      return columnsWithPercentage.includes(column) ? "%" : "";
    };

    return {
      selectedFacilityIds,
      filteredFacilities,
      dashboardData,
      isHighlighted,
      getColor,
      highlightColumn,
      conversionTable,
      selectedPeriod,
      selectedTrends,
      getUnit,
    };
  },

  data() {
    return {
      selectedRegions: ["Three by Three Storage"],
      selectedFacilities: [
        "3x3 Self Storage - Denton",
        "3x3 Self Storage - Fort Worth",
        "3x3 Self Storage - Waco",
        "3x3 Self Storage - lewisville",
        "3x3 Self Storage - Abllene",
      ],
      selectedDate: {
        before: "2024-06-05T12:14:10Z",
        after: "2024-05-05T12:14:10Z",
      },
      selectedTableName: "revenue",
      tableNames: [
        { name: "Conversion", value: "conversion" },
        { name: "Revenue", value: "revenue" },
      ],
      trends: [
        { name: "By Trends", value: "byTrends" },
        { name: "By Months", value: "byMonths" },
      ],
      period: [
        { name: "Last 12 Months", value: "last12Months" },
        { name: "Custom Period", value: "customPeriod" },
      ],
      selectedRegion: "Grace Combs",
      selectedFacility: "Facility A",
    };
  },
  watch: {
    filteredFacilities(newFacilities) {
      this.selectedFacilityIds = newFacilities.map((facility) => facility.id);
    },
    selectedFacilityIds(facilityIds) {
      this.dashboardData = facilityData.filter((facility) =>
        facilityIds.includes(facility.id)
      );
    },
  },
  beforeMount() {
    this.selectedFacilityIds = this.filteredFacilities.map(
      (facility) => facility.id
    );
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-container td {
  min-width: 150px;
}

.total td {
  font-weight: bold;
}

.negative-value {
  color: red;
}

.positive-value {
  color: green;
}
</style>
