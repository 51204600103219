<template>
  <v-card>
    <v-skeleton-loader v-if="loading" type="article, actions" />
  </v-card>
</template>

<script>
import useNotifications from "./useNotifications";
import { useRoute } from "vue-router/composables";
import { ref } from "vue";

export default {
  name: "NotificationResolution",
  setup() {
    const { loading, redirectToNotification } = useNotifications();
    const $route = useRoute();
    const notificationId = ref($route?.params?.id);

    return { loading, notificationId, redirectToNotification };
  },
  beforeMount() {
    this.redirectToNotification(this.$apollo, this.notificationId);
  },
};
</script>
