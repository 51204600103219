<template>
  <v-card outlined class="black-border" tile style="border: 1px solid black">
    <v-card-title
      class="subtitle-1 pa-2"
      style="background-color: #eee; border-bottom: 1px solid black"
    >
      <v-icon left small class="d-none d-lg-flex">fas fa-user-plus</v-icon>
      <span class="ml-2">Demo Smart Experience</span>
    </v-card-title>

    <ValidationObserver ref="validator" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(sendDemoMessage)">
        <v-alert
          class="mb-0"
          :value="alert.show"
          :type="alert.type"
          :color="alert.color"
          tile
          transition="fade-transition"
        >
          {{ alert.message }}
        </v-alert>

        <v-card-text class="mt-0" style="max-height: 236px; min-height: 236px">
          <ValidatedTextField
            v-model="renter.firstName"
            name="First Name"
            rules="required|min:2|max:250"
          />
          <ValidatedTextField
            v-model="renter.lastName"
            name="Last Name"
            rules="required|min:2|max:250"
          />
          <InputPhoneNumber v-model="renter.primaryPhone" required />
        </v-card-text>

        <v-card-actions>
          <v-row dense>
            <v-col>
              <v-btn
                outlined
                small
                tile
                type="submit"
                color="grey darken-2"
                class="responsive-btn"
                :disabled="!canSendMessage"
              >
                Demo Message
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                outlined
                small
                tile
                color="grey darken-2"
                class="responsive-btn"
                :disabled="!canSendMessage"
                @click="startDemoUnit"
              >
                Demo Unit
              </v-btn>
            </v-col>
            <v-col>
              <PossiblePMS v-slot="{ disabled }">
                <v-btn
                  outlined
                  tile
                  small
                  color="grey darken-2"
                  class="responsive-btn"
                  :disabled="!canSendMessage || disabled"
                  @click="createRenter"
                >
                  Add Renter
                </v-btn>
              </PossiblePMS>
            </v-col>
          </v-row>
        </v-card-actions>
      </form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { createDemoMessage } from "../renters/renter";

import ValidatedTextField from "../utils/ValidatedTextField.vue";
import InputPhoneNumber from "@tod-ui/components/InputPhoneNumber.vue";
import PossiblePMS from "../common/PossiblePMS.vue";
import { required } from "vee-validate/dist/rules";
import { extend, setInteractionMode, ValidationObserver } from "vee-validate";

setInteractionMode("lazy");

extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  name: "RenterDemoForm",
  components: {
    ValidatedTextField,
    ValidationObserver,
    PossiblePMS,
    InputPhoneNumber,
  },
  data: () => ({
    renter: {
      firstName: null,
      lastName: null,
      primaryPhone: null,
    },
    canSendMessage: true,
    alert: {
      show: true,
      message: "Send a Demo Message",
      type: "info",
      color: "#2d2e75",
    },
  }),
  methods: {
    async isValid() {
      const valid = await this.$refs.validator.validate();
      if (!valid) {
        this.showAlert("error", "Complete the required fields");
      }
      return valid;
    },
    async sendDemoMessage() {
      // Don't allow send requests to overlap
      if (!this.canSendMessage) return;
      if (!(await this.isValid())) return;

      try {
        this.canSendMessage = false;
        const response = await createDemoMessage(this.$apollo, {
          ...this.renter,
        });
        if (response.data.createDemoMessage.id) {
          this.showAlert("success", "Demo Message Sent", 15000);
        } else {
          this.showAlert("error", "Demo Message Failed");
        }
      } catch {
        this.showAlert("error", "Demo Message Failed");
      }

      this.canSendMessage = true;
    },
    clearAlert() {
      this.alert = {
        show: true,
        message: "Send a Demo Message",
        type: "info",
        color: "indigo",
      };
    },
    showAlert(type, message, timeout = undefined) {
      this.alert = {
        show: true,
        message,
        type,
      };

      if (timeout && timeout > 0) {
        setTimeout(this.clearAlert, timeout);
      }
    },
    async startDemoUnit() {
      if (!(await this.isValid())) return;

      this.$router.push({
        name: "DemoSmartUnit",
        query: {
          renterFirst: this.renter.firstName,
          renterLast: this.renter.lastName,
          primaryPhone: this.renter.primaryPhone,
        },
      });
    },
    async createRenter() {
      if (!(await this.isValid())) return;

      this.$router.push({
        name: "AddRenter",
        query: {
          renterFirst: this.renter.firstName,
          renterLast: this.renter.lastName,
          primaryPhone: this.renter.primaryPhone,
        },
      });
    },
  },
};
</script>

<style scoped>
.responsive-btn {
  width: 100%;
}
</style>
