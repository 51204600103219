// Query for getting a single organization, typically a Zone/Unit
import gql from "graphql-tag";
import {
  accountFields,
  deviceFields,
  organizationFields,
  userFields,
  incidentFields,
} from "../../graphql/fragments";

export const SMART_UNITS = gql`
  query SmartUnits($id: ID!) {
    smartUnits: organizationDescendants(
      id: $id
      assigned: true
      type: "zone"
      subtype: "unit"
    ) {
      ...OrganizationFields
      devices {
        ...DeviceFields
      }
      billingAccounts(type: "consumer") {
        ...AccountFields
      }
      incidents(sort: "closedAt desc", authorized: false, limit: 1) {
        id
        status
        resolution
      }
      responders {
        escalationLevel
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${accountFields}
  ${deviceFields}
  ${organizationFields}
`;

export const ORGANIZATION_DEVICES = gql`
  query OrganizationDevices(
    $id: ID!
    $searchText: String
    $status: String
    $dispositions: [String]
  ) {
    organizationDevices(
      id: $id
      searchText: $searchText
      deviceSubtypes: ["defender", "water_sensor", "door_sensor"]
      status: $status
      dispositions: $dispositions
    ) {
      ...DeviceFields
      assignmentHistory {
        organizationId
        organizationName
        timestamp
      }
      organization {
        id
      }
    }
  }
  ${deviceFields}
`;

export const INVENTORY_SMART_UNITS = gql`
  query InventorySmartUnits($id: ID!, $searchText: String) {
    inventorySmartUnits: organizationDescendants(
      id: $id
      assigned: false
      type: "zone"
      subtype: "unit"
      searchText: $searchText
    ) {
      ...OrganizationFields
      responders {
        id
        demoEndingAt
        status
        user {
          id
          firstName
          lastName
        }
      }
      devices {
        ...DeviceFields
        assignmentHistory {
          organizationId
          organizationName
          timestamp
        }
        organization {
          id
        }
      }
    }
  }
  ${organizationFields}
  ${deviceFields}
`;

export const ENDED_SERVICE_SMART_UNITS = gql`
  query EndedServiceSmartUnits($id: ID!, $searchText: String) {
    endedServiceSmartUnits: organizationDescendants(
      id: $id
      accountStatus: "ended_service"
      type: "zone"
      subtype: "unit"
      searchText: $searchText
    ) {
      ...OrganizationFields
      responders {
        id
        status
        user {
          id
          firstName
          lastName
        }
      }
      devices {
        ...DeviceFields
        assignmentHistory {
          organizationId
          organizationName
          timestamp
        }
        organization {
          id
        }
      }
    }
  }
  ${organizationFields}
  ${deviceFields}
`;

// We're using this to check for existing zones/units and whether the unit
// is currently "assigned" and has devices, to inform the UI/UX.
export const SEARCH_EXISTING_UNITS = gql`
  query SearchExistingUnits(
    $id: ID!
    $searchText: String!
    $assigned: Boolean
    $limit: Int
  ) {
    searchExistingUnits: organizationDescendants(
      id: $id
      searchText: $searchText
      type: "zone"
      assigned: $assigned
      limit: $limit
      sort: "name"
    ) {
      ...OrganizationFields
      devices {
        ...DeviceFields
      }
      responders {
        id
        escalationLevel
        user {
          ...UserFields
        }
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
  ${userFields}
`;

export const GET_SMART_UNIT = gql`
  query GetSmartUnit($id: ID!, $expanded: Boolean) {
    organization(id: $id, expanded: $expanded) {
      ...OrganizationFields
      devices {
        ...DeviceFields
      }
      billingAccounts(type: "consumer") {
        id
        name
        status
        type
      }
      responders {
        id
        escalationLevel
        contactMethod {
          id
          type
          value
        }
        user {
          id
          firstName
          lastName
        }
      }
      organizationDetails {
        description
        unitAmenities
        unitDesign
        entryLocation
        doorType
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
`;

export const UPDATE_SMART_UNIT = gql`
  mutation UpdateSmartUnit(
    $id: ID!
    $name: String!
    $unitTypeId: ID
    $location: InputLocation
  ) {
    updateSmartUnit(
      id: $id
      name: $name
      unitTypeId: $unitTypeId
      location: $location
    ) {
      ...OrganizationFields
    }
  }
  ${organizationFields}
`;

export const GET_DEVICE = gql`
  query GetDevice($id: ID!) {
    device(id: $id) {
      ...DeviceFields
      organization {
        ...OrganizationFields
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
`;

export const UPDATE_DEVICE = gql`
  mutation UpdateDevice(
    $id: ID!
    $externalId: String!
    $disposition: String
    $organizationId: ID!
    $type: String!
    $specs: InputDeviceSpec
    $dateReportedMissing: Date
  ) {
    updateDevice(
      id: $id
      disposition: $disposition
      externalId: $externalId
      organizationId: $organizationId
      type: $type
      specs: $specs
      dateReportedMissing: $dateReportedMissing
    ) {
      ...DeviceFields
      organization {
        ...OrganizationFields
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
`;

export const SMART_UNIT_INCIDENTS = gql`
  query SmartUnitIncidents(
    $organizationId: ID
    $parentId: ID
    $dateTimeFilter: DateTimeFilter!
    $sort: String
    $type: String
    $assigned: Boolean
  ) {
    incidents(
      organizationId: $organizationId
      parentId: $parentId
      dateTimeFilter: $dateTimeFilter
      sort: $sort
      type: $type
      assigned: $assigned
    ) {
      ...IncidentFields
      devices {
        id
        externalId
        name
      }
      organization {
        id
        name
      }
    }
  }
  ${incidentFields}
`;

// query to get a timestamp for 'organization_added' (start renting) event
export const USER_BILLING_ACCOUNT_EVENTS = gql`
  query UserBillingAccountEvents($organizationId: ID!, $renterId: ID!) {
    userBillingAccounts(organizationId: $organizationId, userId: $renterId) {
      billingAccount {
        events(sort: "timestamp desc", types: ["billing_account"]) {
          data
          name
          timestamp
        }
      }
    }
  }
`;

export const UPDATE_UNIT_DETAILS = gql`
  mutation UpdateUnitDetails(
    $organizationId: ID!
    $description: String
    $unitDesign: [String]
    $unitAmenities: [String]
  ) {
    updateOrganizationDetails(
      organizationId: $organizationId
      description: $description
      unitDesign: $unitDesign
      unitAmenities: $unitAmenities
    ) {
      description
      unitDesign
      unitAmenities
    }
  }
`;
