<template>
  <div>
    <template v-if="climateConfig && !climateConfig.disabled">
      <v-icon :color="humidityIconColor" :small="small">
        {{ humidityIcon }}
      </v-icon>
      {{ humidityInPercentage }}

      <v-icon class="ml-2" :color="temperatureIconColor" :small="small">
        {{ temperatureIcon }}
      </v-icon>
      {{ temperatureInFahrenheit }}
    </template>
  </div>
</template>

<script>
import { celsiusToFahrenheit } from "../utils/converters";
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "ZoneClimateCluster",
  props: {
    profile: {
      type: Object,
      required: true,
    },
    devices: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    climateConfig() {
      return this.profile.climateConfig;
    },
    averageHumidity() {
      if (this.devices.length === 0) return 0;

      const values = this.devices.reduce((acc, { humidity, type }) => {
        if (type !== "een") {
          const number = Number(humidity);
          if (!isNaN(number)) acc.push(number);
        }
        return acc;
      }, []);

      return values.reduce((h, x) => h + x, 0) / values.length;
    },
    averageTemperature() {
      if (this.devices.length === 0) return 0;

      const values = this.devices.reduce((acc, { temperature, type }) => {
        if (type !== "een") {
          const number = Number(temperature);
          if (!isNaN(number)) acc.push(number);
        }
        return acc;
      }, []);

      return values.reduce((h, x) => h + x, 0) / values.length;
    },
    humidityIcon() {
      return "fas fa-raindrops";
    },
    humidityIconColor() {
      if (!this.climateConfig) return undefined;

      const lowThreshold = Number(this.climateConfig.lowHumidityThreshold);
      const highThreshold = Number(this.climateConfig.highHumidityThreshold);

      if (!isNaN(lowThreshold) && this.averageHumidity < lowThreshold) {
        return "blue lighten-3";
      }

      if (!isNaN(highThreshold) && this.averageHumidity > highThreshold) {
        return "error";
      }

      return "success";
    },
    humidityInPercentage() {
      const humidity = this.averageHumidity;
      return `${humidity.toFixed(0)}%`;
    },
    temperatureIcon() {
      if (!this.climateConfig) return undefined;

      const lowThreshold = Number(this.climateConfig.lowTemperatureThreshold);
      const highThreshold = Number(this.climateConfig.highTemperatureThreshold);

      if (!isNaN(lowThreshold) && this.averageTemperature < lowThreshold) {
        return "fas fa-temperature-frigid";
      }

      if (!isNaN(highThreshold) && this.averageTemperature > highThreshold) {
        return "fas fa-temperature-hot";
      }

      return "fas fa-thermometer-half";
    },
    temperatureIconColor() {
      if (!this.climateConfig) return undefined;

      const lowThreshold = Number(this.climateConfig.lowTemperatureThreshold);
      const highThreshold = Number(this.climateConfig.highTemperatureThreshold);

      if (!isNaN(lowThreshold) && this.averageTemperature < lowThreshold) {
        return "blue lighten-3"; // icey-blue
      }

      if (!isNaN(highThreshold) && this.averageTemperature > highThreshold) {
        return "error";
      }

      return "success";
    },
    temperatureInFahrenheit() {
      const temp = celsiusToFahrenheit(this.averageTemperature);
      return `${temp.toFixed(0)} °F`;
    },
  },
  methods: {
    celsiusToFahrenheit,
    titleCase,
  },
};
</script>
