<template>
  <v-card v-if="!loading" outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <div style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
    </v-card-title>
    <v-card-text>
      <DialogLayout
        v-model="isOpen"
        title="Viewing Video Clip"
        width="900px"
        @close="closeDialog"
      >
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          class="loader"
        ></v-progress-circular>
        <PopupVideoView :video="video" />
      </DialogLayout>

      <v-data-table
        :loading="loading"
        :headers="getHeaders"
        :items="incidents"
        :search="search"
        :custom-filter="customFilter"
        :sort-by="['startedAt']"
        :sort-desc="[true]"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.actions="{ item }">
          <ButtonView @click="gotoIncident(item.id)" />
        </template>

        <template #item.incidentType="{ item }">
          <IconIncidentType :incident="item" class="mr-2" />
          {{ item.incidentType }}
        </template>

        <template #item.device_location="{ item }">
          <div v-if="item?.deviceLocation">
            {{ item?.deviceLocation }}
          </div>
          <div v-else class="grey--text">N/A</div>
        </template>
        <template #item.startedAt="{ item }">
          {{ facilityFormatDateTime4Humans(item.startedAt) }}
        </template>
        <template #item.duration="{ item }">
          {{ durationInWords(item.startedAt, item.endedAt) }}
        </template>
        <template #item.statusText="{ item }">
          {{ incidentStatus(item) }}
        </template>

        <template #item.viewVideo="{ item }">
          <div v-if="devices?.externalId">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-btn
                    depressed
                    :disabled="!isVideoAvailable(item.startedAt)"
                    :color="
                      isVideoAvailable(item.startedAt) ? 'transparent' : 'grey'
                    "
                    title="View Video"
                    @click="
                      fetchVideo(
                        devices.externalId,
                        item.startedAt,
                        currentFacilityId
                      )
                    "
                  >
                    <v-icon
                      :color="
                        isVideoAvailable(item.startedAt) ? 'error' : 'grey'
                      "
                      left
                    >
                      fas fa-play-circle
                    </v-icon>
                    View Video
                  </v-btn>
                </span>
              </template>
              <span v-if="!isVideoAvailable(item.startedAt)">
                Video is not available. Videos are only retained for
                {{ eenVideoRetentionDays }} days.
              </span>
              <span v-if="isVideoAvailable(item.startedAt)">
                Click here to view the video.
              </span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";
import SearchField from "@atoms/SearchField.vue";

import ButtonView from "@atoms/ButtonView.vue";
import useFacility from "../authentication/useFacility";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import { durationInWords } from "@tod-ui/helpers/datetime";
import { incidentStatus } from "@components/common/helpers/incident";

import { EEN_VIDEO_FEED, EEN_CAMERA_SETTINGS } from "./graphql";
import DialogLayout from "./DialogLayout.vue";
import PopupVideoView from "./PopupVideoView.vue";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "CardZoneIncidentTable",
  components: {
    ButtonView,
    SearchField,
    IconIncidentType,
    DialogLayout,
    PopupVideoView,
  },
  props: {
    incidents: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => null,
    },
    devices: {
      type: Object,
      default: () => null,
    },
  },
  setup(props) {
    const { facilityFormatDateTime4Humans, currentFacilityId } = useFacility();
    const { addAlert } = useAlerts();
    const color = "#aaaaaa";

    return {
      facilityFormatDateTime4Humans,
      color,
      currentFacilityId,
      addAlert,
    };
  },
  data: () => ({
    search: "",
    isOpen: false,
    video: null,
    eenVideoRetentionDays: 0,
    loadingConfig: false,
  }),
  computed: {
    getHeaders() {
      const baseHeaders = [
        {
          text: "Details",
          align: "start",
          filterable: false,
          sortable: false,
          value: "actions",
          width: "1%",
        },
        { text: "Type", value: "incidentType" },
        { text: "Device Location", value: "device_location" },
        { text: "Started At", value: "startedAt" },
        {
          text: "Duration",
          value: "duration",
          toString: (duration) => (duration < 1 ? 1 : Math.round(duration)),
        },
        { text: "Status", value: "statusText" },
      ];
      if (this.devices?.externalId) {
        baseHeaders.push({ text: "Actions", value: "viewVideo" });
      }
      return baseHeaders;
    },
  },
  watch: {
    "devices.externalId": {
      immediate: true,
      async handler(newExternalId) {
        if (newExternalId && this.currentFacilityId) {
          await this.fetchEENVideoConfig(newExternalId, this.currentFacilityId);
        }
      },
    },
  },

  methods: {
    isVideoAvailable(startedAt) {
      if (!this.eenVideoRetentionDays) return false;

      const now = new Date();
      const startDate = new Date(startedAt);
      const diffInDays = (now - startDate) / (1000 * 60 * 60 * 24);

      return diffInDays <= this.eenVideoRetentionDays;
    },

    titleCase,
    gotoIncident(incidentId) {
      this.$router.push({
        name: "ZoneIncident",
        params: {
          incident_id: incidentId,
        },
      });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      let _search = search.toLowerCase();

      const type = `${item.type}`;
      if (type.toLowerCase().indexOf(_search) >= 0) return true;
      if (item.organization) {
        const name = `${item.organization.name}`;
        if (name.toLowerCase().indexOf(_search) >= 0) return true;
      }

      if (
        this.facilityFormatDateTime4Humans(item.startedAt).indexOf(_search) >= 0
      )
        return true;
    },
    async closeDialog() {
      this.isOpen = false;
      if (this.$refs.popupVideoView) {
        this.$refs.popupVideoView.pauseVideo();
      }
      this.video = null;
    },

    async fetchVideo(deviceId, fromTimeStamp, organization_id) {
      try {
        this.video = null;
        this.isOpen = true;

        fromTimeStamp = new Date(fromTimeStamp)
          .toISOString()
          .replace("Z", "+00:00");
        const response = await this.$apollo.query({
          query: EEN_VIDEO_FEED,
          variables: { fromTimeStamp, deviceId, organization_id },
        });

        this.video = response?.data?.eenVideoFeed?.video ?? "";
      } catch (error) {
        console.error(error);
        this.addAlert({
          type: "error",
          message: "There was an error fetching the video. Please try again.",
        });
      }
    },
    async fetchEENVideoConfig(cameraId, organizationId) {
      this.loadingConfig = true;
      try {
        const response = await this.$apollo.query({
          query: EEN_CAMERA_SETTINGS,
          variables: { cameraId, organizationId },
        });

        this.eenVideoRetentionDays =
          response?.data?.eenCameraSettings?.cloudRetentionDays ?? 0;
      } catch (error) {
        console.error(error);
        this.addAlert({
          type: "error",
          message:
            "There was an error fetching the video camera settings. Please try again.",
        });
      } finally {
        this.loadingConfig = false;
      }
    },
    durationInWords,
    incidentStatus,
  },
};
</script>
<style scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
