<template>
  <LayoutPage :title="['Management Summary', 'Average Visits']">
    <template #actions>
      <SwitchRegionalView v-model="regionalView" />
    </template>
    <v-row>
      <v-col cols="12" md="8">
        <RegionAndFacilitySelect />
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col>
            <v-select
              v-model="selectedVisitsFilter"
              :items="numberOfVisitsOptions"
              label="Average # of Visits"
              item-text="name"
              item-id="value"
              outlined
              dense
            />
          </v-col>
          <v-col>
            <v-select
              v-model="selectedTimeFilter"
              :items="durationPerVisitsOptions"
              label="Average Time Per Visit"
              item-text="name"
              item-id="value"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <DateTimeFilter
        v-model="dateTime"
        :ranges="['Today', '2d', '1m']"
        default="1m"
        custom
        inverted
      />
      <div class="ml-4">
        <SearchField v-model="tableState.search" />
      </div>
    </div>
    <v-skeleton-loader
      :loading="loading"
      type="table-thead, table-row-divider@10"
    >
      <v-data-table
        :items="tableData.items"
        :headers="tableData.headers"
        :search="tableState.search"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.visitsNumber="{ item }">
          <TableCellWrapper
            :is-highlighted="highlightColumn === 'visitsNumber'"
          >
            {{ item.visitsNumber }}
          </TableCellWrapper>
        </template>
        <template #item.avgVisitDurationMinute="{ item }">
          <TableCellWrapper
            :is-highlighted="highlightColumn === 'visitsDuration'"
          >
            {{ roundValue(item.avgVisitDurationMinute) }}
          </TableCellWrapper>
        </template>
        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="tableData.items"
            :headers="tableData.headers"
            filename="Average_Visits_Report"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import SearchField from "@atoms/SearchField.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import useDashboard from "../useDashboard";
import { FACILITY_VISITS_REPORT } from "./graphql";
import { formatToISODate, getMonth } from "@tod-ui/helpers/datetime";
import useDataTable from "@components/common/useDataTable";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import { VISITS_FREQUENCY, VISIT_DURATION } from "@tod-ui/constants/dashboard";
import { useRoute } from "vue-router/composables";
import { computed, ref, watchEffect } from "vue";
import TableCellWrapper from "@layout/TableCellWrapper.vue";
import SwitchRegionalView from "@atoms/SwitchRegionalView.vue";
import { roundValue } from "@tod-ui/helpers/math";
import useUser from "@components/authentication/useUser";

export default {
  components: {
    LayoutPage,
    RegionAndFacilitySelect,
    SearchField,
    DateTimeFilter,
    ButtonExportCSV,
    ButtonPrint,
    TableCellWrapper,
    SwitchRegionalView,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const { selectedFacilities } = useDashboard();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["id desc"],
    });
    const selectedTimeFilter = ref({ min: 0, max: undefined });
    const selectedVisitsFilter = ref({ min: 0, max: undefined });

    const currentMonth = computed(() => {
      return getMonth(0, 0, defaultOrganization.value.timeZone);
    });
    const currentDate = computed(() => {
      const date = new Date().toISOString();
      return formatToISODate(date, defaultOrganization.value.timeZone);
    });
    const dateTime = ref({
      after: currentMonth.value,
      before: currentDate.value,
    });
    const route = useRoute();

    const highlightColumn = computed(() => route.query.highlight_column);
    const selectOption = computed(() => route.query.select_option);

    const numberOfVisitsOptions = ref([
      {
        name: VISITS_FREQUENCY.GREATER_THAN_40,
        value: { min: 41, max: undefined },
      },
      { name: VISITS_FREQUENCY.BETWEEN_20_AND_40, value: { min: 20, max: 40 } },
      { name: VISITS_FREQUENCY.BETWEEN_1_AND_20, value: { min: 1, max: 20 } },
      { name: VISITS_FREQUENCY.LESS_THAN_1, value: { min: undefined, max: 0 } },
    ]);

    const durationPerVisitsOptions = [
      {
        name: VISIT_DURATION.GREATER_THAN_20,
        value: { min: 21, max: undefined },
      },
      { name: VISIT_DURATION.BETWEEN_10_AND_20, value: { min: 10, max: 20 } },
      { name: VISIT_DURATION.BETWEEN_2_AND_10, value: { min: 2, max: 10 } },
      { name: VISIT_DURATION.LESS_THAN_2, value: { min: undefined, max: 1 } },
    ];

    watchEffect(() => {
      if (highlightColumn.value === "visitsNumber") {
        selectedVisitsFilter.value = numberOfVisitsOptions.value.find(
          (item) => item.name === selectOption.value
        )?.value || { min: undefined, max: undefined };
      } else if (highlightColumn.value === "visitsDuration") {
        selectedTimeFilter.value = durationPerVisitsOptions.find(
          (item) => item.name === selectOption.value
        )?.value || { min: undefined, max: undefined };
      }
    });

    const formattedDate = computed(() => ({
      after: formatToISODate(
        dateTime.value.after,
        defaultOrganization.value.timeZone
      ),
      before: formatToISODate(
        dateTime.value.before,
        defaultOrganization.value.timeZone
      ),
    }));

    return {
      selectedFacilities,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      highlightColumn,
      selectOption,
      selectedTimeFilter,
      selectedVisitsFilter,
      dateTime,
      durationPerVisitsOptions,
      numberOfVisitsOptions,
      formattedDate,
      roundValue,
    };
  },
  data() {
    return {
      averageVisitsReport: [],
      regionalView: true,
    };
  },
  computed: {
    regionWiseReport() {
      const regionalReports = this.averageVisitsReport.reduce((acc, report) => {
        const { region, visitsNumber, avgVisitDurationMinute } = report;

        if (!acc[region]) {
          acc[region] = {
            regionName: region,
            totalVisits: 0,
            totalDuration: 0,
          };
        }
        // Aggregate the visits and duration
        acc[region].totalVisits += visitsNumber;
        acc[region].totalDuration += avgVisitDurationMinute * visitsNumber;

        return acc;
      }, {});

      return Object.values(regionalReports).map((region) => {
        return {
          organizationPath: region.regionName,
          visitsNumber: region.totalVisits,
          avgVisitDurationMinute: region.totalDuration / region.totalVisits,
        };
      });
    },
    loading() {
      return this.$apollo.loading;
    },
    tableData() {
      return {
        headers: [
          this.regionalView
            ? { text: "Organization / Region", value: "organizationPath" }
            : { text: "Facility Name", value: "facilityName" },
          ...(this.regionalView
            ? []
            : [
                { text: "Smart Unit", value: "organizationName" },
                { text: "Renter", value: "responderName" },
              ]),
          { text: "No Of Visits", value: "visitsNumber" },
          {
            text: "Average Duration per Visit (minutes)",
            value: "avgVisitDurationMinute",
          },
        ],
        items: this.regionalView
          ? this.regionWiseReport
          : this.averageVisitsReport,
      };
    },
  },
  apollo: {
    averageVisitsReport: {
      query: FACILITY_VISITS_REPORT,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          avgTimeFilter: this.selectedTimeFilter,
          numberOfVisitsFilter: this.selectedVisitsFilter,
          dateFilter: this.formattedDate,
        };
      },
      update: (data) => data.facilityVisitsReport,
      skip() {
        return this.selectedFacilities.length === 0 || !this.dateTime.after;
      },
    },
  },
};
</script>
