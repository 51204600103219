<template>
  <v-card outlined flat tile>
    <div :style="`background-color: ${color}; height: 18px`"></div>
    <v-card-title class="subtitle-1">
      {{ title }}
      <v-spacer />
      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['Today', '2d', '1w']"
        default="2d"
        class="mr-4"
      />
      <div style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="loading"
        :headers="[
          {
            text: 'Details',
            align: 'start',
            filterable: false,
            sortable: false,
            value: 'actions',
            width: '1%',
          },
          { text: 'Smart Zone', value: 'organization.name' },
          { text: 'Type', value: 'type' },
          { text: 'Started At', value: 'startedAt' },
        ]"
        :items="incidents"
        :search="search"
        :custom-filter="customFilter"
        :sort-by="['startedAt']"
        :sort-desc="[true]"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.actions="{ item }">
          <ButtonView @click="gotoIncident(item.id)" />
        </template>

        <template #item.type="{ item }">
          {{ titleCase(item.type) }}
        </template>

        <template #item.startedAt="{ item }">
          {{ facilityFormatDateTime4Humans(item.startedAt) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonView from "@atoms/ButtonView.vue";

import { titleCase } from "@tod-ui/helpers/strings";
import useFacility from "../authentication/useFacility";
import useDashboardIncidents from "./useDashboardIncidents";
import { ref, computed } from "vue";

export default {
  name: "ZoneIncidentTable",
  components: {
    ButtonView,
    SearchField,
    DateTimeFilter,
  },
  props: {
    incidentType: {
      validator: (val) => val === "motion" || val === "climate",
      required: true,
    },
  },
  setup(props) {
    const { facilityFormatDateTime4Humans, facilityTimeZone } = useFacility();
    const { zoneIncidents, zoneIncidentsLoading, fetchZoneIncidents } =
      useDashboardIncidents();
    const dateRange = ref({ after: undefined });

    const title = computed(() => {
      switch (props.incidentType) {
        case "motion":
          return "Zone Motion";
        case "climate":
          return "Zone Climate Alerts";
      }
      return "Zone Incidents";
    });

    const color = "#aaaaaa";

    return {
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      incidents: zoneIncidents,
      loading: zoneIncidentsLoading,
      fetchZoneIncidents,
      dateRange,
      title,
      color,
    };
  },
  data: () => ({
    search: "",
  }),
  watch: {
    dateRange(value) {
      this.fetchZoneIncidents(this.$apollo, this.incidentType, value?.after);
    },
  },
  methods: {
    titleCase,
    gotoIncident(incidentId) {
      this.$router.push({
        name: "ZoneIncident",
        params: {
          incident_id: incidentId,
        },
      });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `incident`
      let _search = search.toLowerCase();

      //Filter on 'type
      const type = `${item.type}`;
      if (type.toLowerCase().indexOf(_search) >= 0) return true;
      // Filter on `name`
      if (item.organization) {
        const name = `${item.organization.name}`;
        if (name.toLowerCase().indexOf(_search) >= 0) return true;
      }
      // Filter on `startedAt`
      if (
        this.facilityFormatDateTime4Humans(item.startedAt).indexOf(_search) >= 0
      )
        return true;
    },
  },
};
</script>
