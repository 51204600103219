<template>
  <CardLayout title="Incident Information" :loading="isLoading">
    <DialogLayout
      v-if="isOpen"
      v-model="isOpen"
      title="Viewing Video Clip"
      width="900px"
      @close="closeDialog"
    >
      <v-progress-circular
        v-if="isLoadingVideo"
        indeterminate
        color="primary"
        class="loader"
      ></v-progress-circular>
      <PopupVideoView :video="video" />
    </DialogLayout>

    <dl
      v-if="incident"
      class="property-list my-4"
      :class="{
        'd-flex flex-column': $vuetify.breakpoint.smAndDown,
        'two-columns': !$vuetify.breakpoint.smAndDown,
      }"
    >
      <dt>Incident ID</dt>
      <dd>{{ incident.shortId }}</dd>
      <dt>Facility</dt>
      <dd>{{ incident.facilityName }}</dd>
      <dt>Incident Type</dt>
      <dd>
        <IconIncidentType :incident="incident" />
        {{ incident.typeName }}
      </dd>
      <dt>Smart Unit/Zone</dt>
      <dd>{{ incident.organization.name }}</dd>
      <dt>Priority</dt>
      <dd>
        <SeverityChip :severity="titleCase(incident.priority)" />
      </dd>
      <dt>Renter Name</dt>
      <dd>
        <UserIcon
          v-if="incident.hasDisabledLienResponder"
          left
          :responder="incident.responder"
        />
        {{ incident.responder.name }}
      </dd>
      <dt>Incident Status</dt>
      <dd>
        <IconIncidentStatus small :incident="incident" />
        {{ incident.statusName }}
      </dd>
      <dt>Incident Disposition</dt>
      <dd>{{ incident.incidentDisposition }}</dd>
      <template v-if="isVideoCameraEnabled">
        <dt>Duration</dt>
        <dd class="d-flex align-center">
          {{ incident.duration }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn
                  class="ml-2"
                  depressed
                  :disabled="!isVideoAvailable(incident.incidentStartedAt)"
                  :color="
                    isVideoAvailable(incident.startedAt)
                      ? 'transparent'
                      : 'white'
                  "
                  title="View Video"
                  @click="
                    fetchVideo(
                      isVideoCameraEnabled.externalId,
                      incident.incidentStartedAt,
                      incident.organization.ancestors[0].id
                    )
                  "
                >
                  <v-icon
                    :color="
                      isVideoAvailable(incident.incidentStartedAt)
                        ? 'error'
                        : 'grey'
                    "
                    left
                  >
                    fas fa-play-circle
                  </v-icon>
                  View Video
                </v-btn>
              </span>
            </template>
            <span v-if="!isVideoAvailable(incident.incidentStartedAt)">
              Video not available. Videos are only retained for
              {{ eenVideoRetentionDays }} days.
            </span>
            <span v-if="isVideoAvailable(incident.incidentStartedAt)">
              Click here to view the video.
            </span>
          </v-tooltip>
        </dd>
      </template>

      <dt>Incident Started On</dt>
      <dd>{{ incident.startedAt }}</dd>

      <dt>Incident Due Date</dt>
      <dd>{{ incident.dueAt }}</dd>
    </dl>

    <template v-if="!isLoading && incident && !incident.needsSurvey" #actions>
      <v-btn color="grey lighten-2" @click="$router.go(-1)">Done</v-btn>
    </template>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import DialogLayout from "@components/smart_zones/DialogLayout.vue";
import IconIncidentStatus from "@atoms/IconIncidentStatus.vue";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import UserIcon from "../renters/UserIcon.vue";
import PopupVideoView from "@components/smart_zones/PopupVideoView.vue";
import { ref, computed } from "vue";
import useIncident from "./useIncident";
import useAlerts from "@tod-ui/composables/useAlerts";
import { EEN_VIDEO_FEED, EEN_CAMERA_SETTINGS } from "../smart_zones/graphql";
import { titleCase } from "@tod-ui/helpers/strings";
import SeverityChip from "@tod-ui/components/SeverityChip.vue";

export default {
  name: "CardIncidentInfo",
  components: {
    CardLayout,
    DialogLayout,
    IconIncidentStatus,
    IconIncidentType,
    UserIcon,
    PopupVideoView,
    SeverityChip,
  },
  props: {
    singleColumn: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { incident, loading: incidentLoading } = useIncident();
    const { addAlert } = useAlerts();
    const isOpen = ref(false);
    const video = ref(null);
    const eenVideoRetentionDays = ref(0);
    const isVideoAvailable = (startedAt) => {
      if (!eenVideoRetentionDays.value) return false;
      const now = new Date();
      const startDate = new Date(startedAt);
      const diffInDays = (now - startDate) / (1000 * 60 * 60 * 24);
      return diffInDays <= eenVideoRetentionDays.value;
    };
    const isLoadingVideo = ref(false);
    const currentVideoParams = ref({
      deviceId: null,
      fromTimeStamp: null,
      organization_id: null,
    });

    const isVideoCameraEnabled = computed(() => {
      if (!incident.value?.organization?.devices) return null;
      return incident.value.organization.devices.find(
        (device) => device.type === "een" && device.subtype === "video_camera"
      );
    });

    const isLoading = computed(
      () => incidentLoading.value || isLoadingVideo.value
    );

    return {
      incident,
      isVideoCameraEnabled,
      isLoading,
      isLoadingVideo,
      isOpen,
      video,
      currentVideoParams,
      addAlert,
      eenVideoRetentionDays,
      isVideoAvailable,
    };
  },

  apollo: {
    videoFeed: {
      query: EEN_VIDEO_FEED,
      skip() {
        return !this.currentVideoParams.deviceId;
      },
      variables() {
        return this.currentVideoParams;
      },
      update: (data) => data?.eenVideoFeed?.video ?? "",
      result({ data }) {
        if (data?.eenVideoFeed?.video) {
          this.video = data.eenVideoFeed.video;
          this.isLoadingVideo = false;
        }
      },
      error(error) {
        console.error("Error fetching video:", error);
        this.addAlert({
          type: "error",
          message: "There was an error fetching the video. Please try again.",
        });
        this.isLoadingVideo = false;
      },
    },
    eenCameraSettings: {
      query: EEN_CAMERA_SETTINGS,
      skip() {
        return (
          !this.isVideoCameraEnabled?.externalId ||
          !this.incident?.organization?.ancestors?.[0]?.id
        );
      },
      variables() {
        return {
          cameraId: this.isVideoCameraEnabled?.externalId,
          organizationId: this.incident?.organization?.ancestors?.[0]?.id,
        };
      },
      result({ data }) {
        if (data?.eenCameraSettings?.cloudRetentionDays) {
          this.eenVideoRetentionDays =
            data.eenCameraSettings.cloudRetentionDays;
        }
      },
      error(error) {
        console.error("Error fetching eenCameraSettings:", error);
        this.addAlert({
          type: "error",
          message:
            "There was an error fetching the Video Camera Settings. Please try again.",
        });
      },
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      return new Date(timestamp).toISOString().replace("Z", "+00:00");
    },

    fetchVideo(deviceId, fromTimeStamp, organization_id) {
      this.video = null;
      this.isOpen = true;
      this.isLoadingVideo = true;
      this.currentVideoParams = {
        deviceId,
        fromTimeStamp: this.formatTimestamp(fromTimeStamp),
        organization_id,
      };
    },

    closeDialog() {
      this.isOpen = false;
      this.video = null;
      this.currentVideoParams = {
        deviceId: null,
        fromTimeStamp: null,
        organization_id: null,
      };
    },
    titleCase,
  },
};
</script>
<style lang="scss" scoped>
.property-list {
  dd {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.two-columns {
    grid-template-columns: repeat(2, max-content auto);
  }
}
</style>
