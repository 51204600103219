import { DECLINE_RED, INCLINE_GREEN } from "@tod-ui/constants/colors";
import { ROLLING_AVG } from "@tod-ui/constants/dashboard";
import {
  formatMonthYear,
  getFormattedMonthName,
} from "@tod-ui/helpers/datetime";
import { roundValue } from "@tod-ui/helpers/math";
import { toLowerCaseAndRemoveSpaces } from "@tod-ui/helpers/strings";

export const conversionRate = "conversionRate";

export function calculateConversionRate(totalAddedSubscriptions, totalMoveIns) {
  if (totalMoveIns === 0) return 0;
  else {
    return roundValue((totalAddedSubscriptions / totalMoveIns) * 100);
  }
}

export function calculatePenetrationRate(totalActiveSubscriptions, totalUnits) {
  if (totalUnits === 0) return 0;
  else {
    return roundValue((totalActiveSubscriptions / totalUnits) * 100);
  }
}
/**
 * Groups data by month and sums the specified result key for each month.
 *
 * @param {Array} data - The data to be grouped. Each element should have a dateKey (in YYYY-MM format).
 * @param {string} dateKey - The key in each element of the data array that contains the date information.
 * @param {string} resultKey - The key in each element of the data array whose values should be summed.
 * @returns {Object} An object containing 'chartData' and 'chartMonths' arrays.
 */
export function groupDataByMonth(
  data,
  dateKey,
  resultKey,
  sortOrder = "asc",
  noOfItems = null
) {
  const groupedData = {};

  data.forEach((facility) => {
    const month = facility[dateKey].slice(0, 7);

    if (!groupedData[month]) {
      groupedData[month] = {
        month,
        [resultKey]: 0,
        facilityCount: 0,
        currentActiveSubscriptions: 0,
        totalMoveIns: 0,
      };
    }

    groupedData[month].facilityCount += 1;

    if (resultKey === conversionRate) {
      groupedData[month].currentActiveSubscriptions +=
        facility["subscriptionsAdded"];
      groupedData[month].totalMoveIns += facility["totalMoveIns"];
    } else {
      groupedData[month][resultKey] += facility[resultKey];
    }
  });

  // Convert the grouped data object to an array and sort it by month
  const sortedData = Object.values(groupedData).sort((a, b) => {
    if (sortOrder === "dsc") {
      return new Date(b.month) - new Date(a.month);
    } else {
      return new Date(a.month) - new Date(b.month);
    }
  });

  // Adjust conversionRate using the provided formula
  if (resultKey === conversionRate) {
    sortedData.forEach((entry) => {
      entry[resultKey] = calculateConversionRate(
        entry.currentActiveSubscriptions,
        entry.totalMoveIns
      );
    });
  }

  let chartData = sortedData.map((entry) => entry[resultKey]);
  let chartMonths = sortedData.map((entry) => entry.month);

  if (noOfItems) {
    chartData = chartData.slice(-1 * noOfItems);
    chartMonths = chartMonths.slice(-1 * noOfItems);
  }

  return {
    chartData,
    chartMonths,
  };
}

export function comparisonPercentage(currentMonthData, comparisonMonthData) {
  // If both months are 0, then display '-', and if the comparison month is 0, display '-' as well.
  if (comparisonMonthData === 0) {
    return "-";
  }
  const percentage =
    ((currentMonthData - comparisonMonthData) / comparisonMonthData) * 100;

  return roundValue(percentage);
}

// Utility function to calculate comparison percentage
export function calculateComparisonPercentage(data, comparisonMonth) {
  const currentMonthData = data.conversionRate
    ? data.conversionRate
    : data.currentMonth;
  const normalizedComparisonMonth = toLowerCaseAndRemoveSpaces(comparisonMonth);
  const comparisonMonthData = data[normalizedComparisonMonth];
  return comparisonPercentage(
    roundValue(currentMonthData),
    roundValue(comparisonMonthData)
  );
}

// Function to initialize facility data structure
function initializeFacilityData(current, monthSet) {
  const facilityData = {
    facilityId: current.facilityId,
    facilityName: current.facilityName,
    region: current.region,
    currentMonth: 0,
    subscriptionsAdded: 0,
    totalMoveIns: 0,
  };
  // Initialize months with 0
  monthSet.forEach((month) => {
    facilityData[toLowerCaseAndRemoveSpaces(month)] = 0;
  });

  return facilityData;
}

// Function to populate data for each facility
function populateData(
  facilityMap,
  data,
  key,
  monthSet,
  currentMonth,
  monthKey,
  timeZone
) {
  data.forEach((current) => {
    const formattedMonth = formatMonthYear(current[monthKey], timeZone);
    const facilityId = current.facilityId;

    if (!facilityMap.has(facilityId)) {
      facilityMap.set(facilityId, initializeFacilityData(current, monthSet));
    }

    const facility = facilityMap.get(facilityId);
    const formattedMonthKey = toLowerCaseAndRemoveSpaces(formattedMonth);

    // Add the new value to the existing one, or set it if it doesn't exist
    facility[formattedMonthKey] =
      (facility[formattedMonthKey] || 0) + (current[key] || 0);

    if (formattedMonthKey === currentMonth) {
      facility.currentMonth = roundValue(facility[formattedMonthKey]);
      if (key === conversionRate) {
        facility.subscriptionsAdded =
          (facility.subscriptionsAdded || 0) +
          (current["subscriptionsAdded"] || 0);
        facility.totalMoveIns =
          (facility.totalMoveIns || 0) + (current["totalMoveIns"] || 0);
      }
    }
  });
}

// Utility function to calculate three months rolling average
function calculateRollingAverage(facilityMap, timeZone) {
  const last3Months = [];
  for (let i = 0; i < 3; i++) {
    last3Months.push(getFormattedMonthName(0, i, timeZone));
  }
  facilityMap.forEach((facility) => {
    let sum = 0;
    let count = 0;
    last3Months.forEach((month) => {
      const formattedMonth = toLowerCaseAndRemoveSpaces(month);
      if (facility.hasOwnProperty(formattedMonth)) {
        sum += roundValue(facility[formattedMonth]);
        count++;
      }
    });
    facility[ROLLING_AVG] = roundValue(count > 0 ? sum / count : 0);
  });
}

// Main function to transform monthly trends data
export function transformMonthlyTrendsData(
  data,
  key,
  months,
  monthKey,
  timeZone
) {
  const monthSet = new Set(
    months.map((month) => formatMonthYear(month, timeZone))
  );
  const currentMonth = toLowerCaseAndRemoveSpaces(
    getFormattedMonthName(0, 0, timeZone)
  );
  const facilityMap = new Map();
  populateData(
    facilityMap,
    data,
    key,
    monthSet,
    currentMonth,
    monthKey,
    timeZone
  );
  calculateRollingAverage(facilityMap, timeZone);
  return Array.from(facilityMap.values());
}

// Helper function for building organization tree
export function buildOrganizationHierarchy(organizations) {
  const parentMap = new Map();
  const hierarchicalData = [];

  organizations.forEach((org) => {
    parentMap.set(org.id, org);
    org.children = [];
  });

  organizations.forEach((org) => {
    if (org.parentId && parentMap.has(org.parentId)) {
      const parent = parentMap.get(org.parentId);
      parent.children.push(org);
    } else {
      hierarchicalData.push(org);
    }
  });

  return hierarchicalData;
}
// Helper function for regional view report
export function groupByRegion(report, fields, avgFields = []) {
  const groupedDataMap = new Map();

  report.forEach((item) => {
    const regionPath = item.region;

    if (!groupedDataMap.has(regionPath)) {
      const newItem = { organizationPath: regionPath, counts: {} };

      // Initialize sum fields to 0
      fields.forEach((field) => {
        newItem[field] = 0;
      });

      // Initialize average fields to 0 and count to 0
      avgFields.forEach((field) => {
        newItem[field] = 0;
        newItem.counts[field] = 0;
      });

      groupedDataMap.set(regionPath, newItem);
    }

    const currentItem = groupedDataMap.get(regionPath);

    // Sum fields
    fields.forEach((field) => {
      currentItem[field] += roundValue(item[field]) || 0;
    });

    // Average fields
    avgFields.forEach((field) => {
      if (item[field] !== undefined) {
        currentItem[field] += item[field];
        currentItem.counts[field] += 1;
      }
    });
  });

  // Calculate averages for the avgFields
  const result = Array.from(groupedDataMap.values()).map((item) => {
    avgFields.forEach((field) => {
      if (item.counts[field] > 0) {
        item[field] = roundValue(item[field] / item.counts[field]);
      } else {
        item[field] = 0; // Handle cases where no data is present
      }
    });
    delete item.counts; // Remove counts as it's no longer needed
    return item;
  });

  return result;
}

export function getColorByPercentage(percentage) {
  if (percentage <= 10) {
    return DECLINE_RED;
  } else if (percentage <= 50) {
    return "#EB9A08";
  } else {
    return INCLINE_GREEN;
  }
}

export function getColorBySeverity(severity) {
  if (severity === "critical") return "rgb(254,10,5)";
  else if (severity === "severe") return "rgb(173,2,1)";
  else if (severity === "substantial") return "rgb(248,127,16)";
  else if (severity === "moderate") return "rgb(105,189,217)";
  else return "rgb(66,171,86)";
}
