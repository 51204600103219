<template>
  <span class="fa-stack" :class="_color" :title="_title" v-bind="$attrs">
    <i :class="icon" class="fa-stack-2x"></i>
    <i v-if="extraIcon" class="fa-stack-1x" :class="extraIcon"></i>
  </span>
</template>
<script>
import { incidentAuthorizedStatus, incidentStatus } from "../helpers/incident";

export default {
  name: "IconIncidentStatus",
  props: {
    incident: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    authorizedStatus() {
      return incidentAuthorizedStatus(this.incident);
    },
    _color() {
      switch (this.authorizedStatus) {
        case "claimed":
        case "presence-confirmed":
          return "success--text";
        case "resolved-surveyed":
        case "un-resolved-surveyed":
        case "ongoing":
          return "info--text";
        case "unauthorized":
        case "unexplained-after-hours":
        case "closed-unexplained-after-access-hours":
          return "warning--text";
        case "closed-vacant":
        case "closed-unexplained":
        case "presence-unconfirmed":
          return "grey--text";
      }
      return "grey--text";
    },
    icon() {
      switch (this.authorizedStatus) {
        case "claimed":
          return "far fa-comment-alt-check";
        case "resolved-surveyed":
          return "far fa-file-check";
        case "un-resolved-surveyed":
          return "far fa-file-check";
        case "unauthorized":
          return "fas fa-comment-alt-exclamation";
        case "unexplained-after-hours":
        case "closed-unexplained-after-access-hours":
          return "fas fa-comment-alt";
        case "presence-confirmed":
          return "far fa-check-circle";
        case "presence-unconfirmed":
          return "far fa-question-circle";
        case "closed-vacant":
        case "closed-unexplained":
          return "far fa-comment-alt";
        case "ongoing":
          return "far fa-comment-alt-dots";
      }
      return "far fa-comment-alt";
    },
    extraIcon() {
      switch (this.authorizedStatus) {
        case "presence-confirmed":
          return "fas fa-scanner-keyboard";
        case "presence-unconfirmed":
        case "closed-vacant":
        case "closed-unexplained":
          return "fas fa-question";
        case "unexplained-after-hours":
        case "closed-unexplained-after-access-hours":
          return "fas fa-clock";
      }
      return null;
    },
    _title() {
      return this.title || incidentStatus(this.incident);
    },
  },
};
</script>

<style scoped>
.fa-stack {
  font-size: 0.7em;
}

.fa-question {
  transform: translateY(-1px);
}

.fa-clock {
  color: white;
  transform: translateY(-2px);
}
</style>
