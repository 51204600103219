<template>
  <LayoutPage
    :title="[
      `Monitoring & Control Hub Dashboard`,
      `Incident Disposition Report`,
    ]"
  >
    <div>
      <div
        v-if="showRegionFacilitySelectBoxes"
        class="d-flex align-items-center justify-space-between"
      >
        <RegionAndFacilitySelect class="" />

        <SelectOrganizationUsers
          v-model="selectedAssigneeId"
          class="mx-2"
          :organization-id="defaultOrganization.id"
          outlined
          dense
        />

        <v-select
          v-model="selectedDisposition"
          :items="dispositionList"
          item-text="text"
          item-value="value"
          dense
          outlined
          label="Incident Disposition"
        />
      </div>
    </div>

    <v-row>
      <v-col>
        <v-card outlined flat tile class="black-border">
          <v-card-title class="title" style="font-weight: 500">
            Incident Disposition Report
          </v-card-title>
          <div class="d-flex justify-end">
            <div class="mr-3">
              <DateTimeFilter
                v-model="dateRange"
                :ranges="['Today', '2d', '1w', '1m']"
                custom
                class="black-border"
                time-zone="America/New_York"
              />
            </div>
            <template>
              <IncidentFilter
                :auto-filter="autoFilter"
                class="mr-4"
                @apply="applyFilter"
              />

              <div style="min-width: 240px" class="mr-2">
                <SearchField v-model="search" />
              </div>
            </template>
          </div>
          <v-skeleton-loader
            :loading="loading"
            type="table-thead, table-row-divider@7"
            class="mx-3"
          >
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="incidents"
                :custom-filter="customFilter"
                :custom-sort="customSort"
                :search="search"
                :sort-by.sync="tableState.sortBy"
                :sort-desc.sync="tableState.sortDesc"
                :page.sync="tableState.page"
                :items-per-page.sync="tableState.itemsPerPage"
                :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
                class="custom-table elevation-1"
              >
                <template #item="{ item }">
                  <tr>
                    <td>
                      <ButtonView title="View" @click="gotoZone(item)" />
                    </td>
                    <td style="min-width: 150px">{{ item.shortId }}</td>
                    <td style="max-width: 300px">
                      <div>
                        <strong>Facility</strong> : {{ item.incidentLocation }}
                      </div>
                      <div class="mt-2">
                        <strong>Smart Unit / Zone</strong> : {{ item.zoneName }}
                      </div>
                    </td>
                    <td>{{ item.incidentType }}</td>
                    <td>
                      <div>
                        {{ item.incidentDisposition }}
                        <span v-if="item.isOverdue" class="overdue-text"
                          >Overdue!</span
                        >
                      </div>
                    </td>
                    <td>
                      <SeverityChip :severity="titleCase(item.priority)" />
                    </td>
                    <td>{{ item.responder }}</td>
                    <td>{{ formatDateTime4Humans(item.startedAt) }}</td>
                    <td>{{ item.dueAt }}</td>
                    <td>{{ item.statusText }}</td>
                  </tr>
                </template>

                <template #footer.prepend>
                  <ButtonExportCSV
                    class="order-last"
                    :table-data="incidents"
                    :headers="headers"
                    filename="Incident Disposition Report"
                  />
                </template>
              </v-data-table>
            </v-card-text>
          </v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router/composables";
import useUser from "@components/authentication/useUser";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import LayoutPage from "@layout/LayoutPage.vue";
import SelectOrganizationUsers from "@components/common/SelectOrganizationUsers.vue";
import useDashboard from "../../dashboard/useDashboard";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import ButtonView from "@atoms/ButtonView.vue";
import { INCIDENT_HISTORY } from "../../../components/reports/graphql";
import useDataTable from "../../common/useDataTable";
import {
  incidentStatus,
  formatIncidentType,
} from "../../common/helpers/incident";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { diff, durationInWords } from "@tod-ui/helpers/datetime";
import { stringSortCollator } from "@tod-ui/helpers/strings";
import useFacility from "../../authentication/useFacility";
import { titleCase } from "@tod-ui/helpers/strings";
import IncidentFilter from "../../reports/IncidentFilter.vue";
import SeverityChip from "@tod-ui/components/SeverityChip.vue";
export default {
  name: "PageIncidentDispositionReport",
  components: {
    RegionAndFacilitySelect,
    LayoutPage,
    IncidentFilter,
    DateTimeFilter,
    SearchField,
    ButtonExportCSV,
    ButtonView,
    SeverityChip,
    SelectOrganizationUsers,
  },
  setup() {
    const router = useRouter();
    const { defaultOrganization } = useUser();
    const defaultOrganizationName = computed(
      () => defaultOrganization.value?.name
    );
    const isLoading = ref(false);
    const { organizationsLoading, selectedFacilities } = useDashboard();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["startedAt"],
      sortDesc: [true],
    });
    const {
      currentFacilityId,
      facilityFeatureEnabled,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      facilityFormatDateTime,
    } = useFacility();

    const selectedAssigneeId = ref(null);
    const selectedDisposition = ref(router.currentRoute.query.status || "");
    const filter = ref({});
    const dateRange = ref({
      after: undefined,
      before: undefined,
    });

    const dispositionList = ref([
      { value: "all", text: "All" },
      { value: "open", text: "Open" },
      { value: "closed", text: "Closed" },
      { value: "in_progress", text: "In Progress" },
      { value: "in_review", text: "In Review" },
    ]);
    const autoFilter = ref({
      status: "",
    });
    onMounted(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const overdue = searchParams.get("overdue");
      if (overdue === "true") {
        autoFilter.value.status = "overdue";
        filter.value = {
          isOverdue: true,
        };
      }
    });

    return {
      autoFilter,
      isLoading,
      defaultOrganization,
      defaultOrganizationName,
      organizationsLoading,
      selectedFacilities,
      currentFacilityId,
      facilityFeatureEnabled,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      facilityFormatDateTime,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      selectedAssigneeId,
      selectedDisposition,
      filter,
      dateRange,
      dispositionList,
    };
  },
  data() {
    return {
      showRegionFacilitySelectBoxes: true,
      search: null,
      limit: parseInt(this.$route.query.count) + 100,
      incidentInfo: {},
      headers: [
        { text: "Details", value: "details" },
        { text: "Incident ID", value: "shortId" },
        { text: "Incident Location", value: "task_location" },
        { text: "Incident Type", value: "incident_type" },
        { text: "Incident Disposition", value: "incident_disposition" },
        { text: "Priority", value: "severity" },
        { text: "Renter Details", value: "renter_details" },
        { text: "Started On", value: "started_on" },
        { text: "Target Close Date", value: "target_close_date" },
        { text: "Status", value: "status" },
      ],
    };
  },
  computed: {
    loading() {
      return this.isLoading;
    },
  },
  apollo: {
    incidents: {
      query: INCIDENT_HISTORY,

      variables() {
        this.isLoading = true;
        const baseVariables = {
          parentId: this.defaultOrganization.id,
          dateTimeFilter: this.dateRange,
          dispositions: [this.selectedDisposition],
          priorities: ["critical", "severe", "moderate", "substantial", "low"],
          ...this.filter,
        };

        if (this.dateRange.after || this.dateRange.before) {
          return baseVariables;
        } else {
          const variables = {
            ...baseVariables,

            limit: this.limit,
          };
          if (this.selectedDisposition === "all") {
            variables.dispositions = [
              "open",
              "closed",
              "in_progress",
              "in_review",
            ];
          }
          return variables;
        }
      },
      update({ incidents }) {
        this.isLoading = false;
        const selectedAssigneeId = this.selectedAssigneeId;
        const selectedDisposition = this.selectedDisposition;
        let filteredIncidents = incidents.filter((incident) => {
          if (incident.priority === "default") {
            return false;
          }
          if (selectedAssigneeId && selectedAssigneeId !== "") {
            const hasMatchingAssignee = incident.tasks.some(
              (task) => task.assignee && task.assignee.id === selectedAssigneeId
            );
            if (!hasMatchingAssignee) {
              return false;
            }
          }

          if (
            selectedDisposition &&
            selectedDisposition !== "" &&
            selectedDisposition !== "all"
          ) {
            if (incident.disposition !== selectedDisposition) {
              return false;
            }
          }
          if (this.filter.severity && this.filter.severity.length > 0) {
            if (
              !this.filter.severity.includes(incident.priority?.toLowerCase())
            ) {
              return false;
            }
          }

          if (this.filter.isOverdue) {
            const hasOverdueTasks = incident.tasks?.some(
              (task) =>
                new Date(task.dueAt) < new Date() &&
                task.resolution !== "completed"
            );
            if (!hasOverdueTasks) {
              return false;
            }
          }

          return true;
        });

        return filteredIncidents
          .filter((incident) => {
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            const status = searchParams.get("status");
            if (status && status === this.selectedDisposition) {
              this.selectedDisposition = status;
            }

            return incident.organization.ancestors.some((ancestor) =>
              this.selectedFacilities.some(
                (facility) => facility.id === ancestor.id
              )
            );
          })
          .map((incident) => {
            const responder = incident.responders?.length
              ? incident.responders.sort(
                  (a, b) => a.escalationLevel - b.escalationLevel
                )[0]
              : "--";

            const endedAt =
              incident.lastUntriggeredAt ||
              incident.closedAt ||
              incident.resolvedAt;

            const isOverdue = incident.tasks?.some(
              (task) =>
                new Date(task.dueAt) < new Date() &&
                task.resolution !== "completed"
            );

            return {
              ...incident,
              shortId: incident.shortId,
              incidentLocation: incident.alerting_strategy.facility_org.name,
              zoneName: incident.organization.name,
              incidentType: formatIncidentType(incident),
              incidentDisposition: incident.disposition,
              priority: incident.priority,
              responder: this.formatResponder(responder),
              startedAt: incident.startedAt,
              endedAt,
              duration: diff(incident.startedAt, endedAt).as("minutes"),
              statusText: incidentStatus(incident),
              dueAt:
                incident.tasks?.length > 0
                  ? this.facilityFormatDateTime4Humans(incident.tasks[0]?.dueAt)
                  : "NA",
              isOverdue,
            };
          });
      },
    },
  },

  watch: {
    selectedAssigneeId: "loadIncidentHistory",
    selectedDisposition: "loadIncidentHistory",
    selectedFacilities: "loadIncidentHistory",
  },
  mounted() {
    this.updateDateRangeFromQuery();
  },
  methods: {
    gotoZone(incident) {
      this.incidentInfo.value = incident;
      this.$router.push({
        name: "Incident",
        params: {
          organization_id: incident.organization.ancestors[0].id,
          incident_id: incident.id,
        },
      });
    },
    formatDateTime4Humans(datetime) {
      return this.facilityFormatDateTime4Humans(datetime);
    },
    applyFilter(filter) {
      // translate to incident query args here
      const _filter = { ...filter };

      _filter.organizationSubtypes = _filter.locations;
      delete _filter.locations;

      if (_filter.severityStatus?.length) {
        _filter.severity = _filter.severityStatus;
      }
      delete _filter.severityStatus;
      if (_filter.overdueStatus?.includes("overdue")) {
        _filter.isOverdue = true;
      }
      delete _filter.overdueStatus;

      if (_filter.statuses?.length) {
        _filter.resolutions = [];

        if (_filter.statuses.includes("authorized")) {
          _filter.resolutions.push("claimed");
        }

        if (_filter.statuses.includes("unauthorized")) {
          _filter.resolutions.push("denied");
          _filter.resolutions.push("surveyed");
        }

        if (_filter.statuses.includes("unacknowledged")) {
          _filter.resolutions.push("unexplained");
        }
      }
      delete _filter.statuses;

      // ignore empty/null filters
      for (let k in _filter) {
        if (_filter[k] === null) {
          delete _filter[k];
        } else if (typeof _filter[k] === "boolean" && !_filter[k]) {
          delete _filter[k];
        } else if (Array.isArray(_filter[k]) && !_filter[k].length) {
          delete _filter[k];
        }
      }

      if (_filter.afterHours) {
        _filter["duringAccessHours"] =
          _filter.afterHours === "after-hours" ? false : undefined;
      }

      this.filter = _filter;
    },
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `incident`
      const _search = search.toLowerCase();
      const match = [
        item.zoneName.toLowerCase(),
        item.incidentLocation.toLowerCase(),
        item.incidentType.toLowerCase(),
        item.incidentDisposition.toLowerCase(),
        item.shortId.toLowerCase(),
        item.priority.toLowerCase(),
        item.responder.toLowerCase(),
        this.facilityFormatDateTime4Humans(item.startedAt),
      ].join(" ");

      return match.indexOf(_search) >= 0;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;

      items.sort((a, b) => {
        if (_sortBy === "statusText") {
          // TODO: Give weights to status and resolutions to determine order
          const aStatus = `${a.status} ${a.resolution}`;
          const bStatus = `${b.status} ${b.resolution}`;

          return (bStatus < aStatus ? -1 : 1) * _sortDesc;
        } else {
          // Default sort comparison
          if (typeof a[_sortBy] === "string") {
            return (
              stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc
            );
          }
          return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
        }
      });

      return items;
    },
    formatResponder(responder) {
      if (!responder?.name) return "--";
      if (!responder?.contactMethod?.value) return responder.name;
      return `${responder.name} @ ${formatPhoneNumber(
        responder.contactMethod.value
      )}`;
    },
    loadIncidentHistory() {
      this.$apollo.queries.incidents.refetch();
    },
    updateDateRangeFromQuery() {
      const newQuery = this.$route.query;

      if (newQuery.date) {
        const now = new Date();
        const offset = now.getTimezoneOffset() * 60000;

        switch (newQuery.date) {
          case "today": {
            const startOfDay = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate()
            );
            const endOfDay = new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate(),
              23,
              59,
              59,
              999
            );

            this.dateRange = {
              after: new Date(startOfDay.getTime() - offset).toISOString(),
              before: new Date(endOfDay.getTime() - offset).toISOString(),
            };
            break;
          }

          case "all":
            this.dateRange = { after: undefined, before: undefined };
            break;
        }
      }
    },
    titleCase,
  },
};
</script>
