<template>
  <v-chip small class="severity-chip" :style="chipStyle">
    <div>{{ severity }}</div>
  </v-chip>
</template>

<script>
import { computed } from "vue";
import { PRIORITIES } from "../constants/task_management";

export default {
  props: {
    severity: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const getColor = (severity) => {
      const normalizedSeverity = severity.toLowerCase();
      if (normalizedSeverity === PRIORITIES.CRITICAL) return "254,10,5";
      if (normalizedSeverity === PRIORITIES.SEVERE) return "173,2,1";
      if (normalizedSeverity === PRIORITIES.MODERATE) return "105,189,217";
      if (normalizedSeverity === PRIORITIES.SUBSTANTIAL) return "248, 127, 16";
      return "66,171,86";
    };

    const chipStyle = computed(() => {
      const color = getColor(props.severity);
      return {
        backgroundColor: `rgba(${color})`,
        color: `rgb(255,255,255)`,
      };
    });

    return {
      chipStyle,
    };
  },
};
</script>

<style>
.severity-chip {
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
