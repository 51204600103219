<template>
  <LayoutPage
    :title="[
      'Management Summary',
      'Penetrations & Annualized Lifetime Revenue',
    ]"
  >
    <v-row>
      <v-col>
        <v-select
          v-model="selectedRegion"
          :items="['Three by Three Storage', 'East Region', 'West Region']"
          label="Select Region"
          dense
          outlined
          multiple
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedFacilities"
          :items="[
            '3x3 Self Storage - Denton',
            '3x3 Self Storage - Fort Worth',
            '3x3 Self Storage - Waco',
            '3x3 Self Storage - lewisville',
            '3x3 Self Storage - Abllene',
          ]"
          label="Select Facilities"
          multiple
          clearable
          item-text="name"
          item-value="id"
          dense
          outlined
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="text-truncate">{{ item }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedFacilities.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          v-model="selectedYear"
          label="Year"
          :items="['2024', '2023']"
          dense
          outlined
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedMonth"
          label="Month"
          :items="[
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
          ]"
          dense
          outlined
        />
      </v-col>
    </v-row>
    <div class="table-container">
      <v-data-table
        :headers="penetrationTable.headers"
        :items="penetrationTable.body"
        :items-per-page="5"
        class="elevation-1"
      >
        <template #body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.facilityName">
              <template v-for="(value, key) in item">
                <CustomTableCell
                  :key="key"
                  :value="value"
                  :is-highlighted="isHighlighted(key)"
                  :unit="getUnit(key)"
                />
              </template>
            </tr>
            <tr class="total">
              <td :class="{ highlighted: isHighlighted('total') }">Total</td>
              <td :class="{ highlighted: isHighlighted('total') }">1574</td>
              <td :class="{ highlighted: isHighlighted('total') }">2187</td>
              <td
                :class="{ highlighted: isHighlighted('facilityPenetrations') }"
              >
                73%
              </td>
              <td :class="{ highlighted: isHighlighted('annualizedRevenue') }">
                $226,656
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CustomTableCell from "./CustomTableCell.vue";
import { facilityData } from "./data";
import useDashboard from "./useDashboard";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";

export default {
  name: "ConversionTable",
  components: {
    LayoutPage,
    CustomTableCell,
  },
  setup() {
    const {
      selectedFacilityIds,
      filteredFacilities,
      dashboardData,
      penetrationTable,
    } = useDashboard();

    const route = useRoute();

    const highlightColumn = computed(() => {
      return route.params.highlightColumn;
    });

    const isHighlighted = (column) => {
      if (highlightColumn.value === "penetration") {
        return "facilityPenetrations" === column;
      } else if (highlightColumn.value === "annualizedRevenue") {
        return "annualizedRevenue" === column;
      }
      return column === highlightColumn.value;
    };

    const getColor = (column, value) => {
      return "rgba(10,1,131,0.8)";
    };

    const getUnit = (column) => {
      if (column === "facilityPenetrations") {
        return "%";
      } else if (column === "annualizedRevenue") {
        return "$";
      }
      return "";
    };

    return {
      selectedFacilityIds,
      filteredFacilities,
      dashboardData,
      isHighlighted,
      getColor,
      highlightColumn,
      penetrationTable,
      getUnit,
    };
  },

  data() {
    return {
      selectedFacilities: [
        "3x3 Self Storage - Denton",
        "3x3 Self Storage - Fort Worth",
        "3x3 Self Storage - Waco",
        "3x3 Self Storage - lewisville",
        "3x3 Self Storage - Abllene",
      ],
      selectedYear: "2024",
      selectedMonth: "September",
      trends: [
        { name: "By Trends", value: "byTrends" },
        { name: "By Months", value: "byMonths" },
      ],
      period: [
        { name: "Last 12 Months", value: "last12Months" },
        { name: "Custom Period", value: "customPeriod" },
      ],
      selectedRegion: "Three by Three Storage",
      selectedFacility: "Facility A",
    };
  },
  watch: {
    filteredFacilities(newFacilities) {
      this.selectedFacilityIds = newFacilities.map((facility) => facility.id);
    },
    selectedFacilityIds(facilityIds) {
      this.dashboardData = facilityData.filter((facility) =>
        facilityIds.includes(facility.id)
      );
    },
  },
  beforeMount() {
    this.selectedFacilityIds = this.filteredFacilities.map(
      (facility) => facility.id
    );
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-container td {
  min-width: 150px;
}

.total td {
  font-weight: bold;
}

.negative-value {
  color: red;
}

.positive-value {
  color: green;
}

.highlighted {
  background-color: rgba(14, 59, 110, 0.2); /* Highlight color */
}
</style>
