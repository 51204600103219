<template>
  <LayoutPage
    :title="`${defaultOrganizationName} Management Summary`"
    :loading="loading"
  >
    <div class="sticky-container">
      <div v-if="showRegionFacilitySelectBoxes">
        <RegionAndFacilitySelect over-view-page />
      </div>
      <div class="d-flex justify-center d-none d-md-none mb-3">
        <v-btn icon fab small @click="toggleDrawer">
          <v-icon color="grey" x-small>
            {{ drawerBtn }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-row>
      <v-col>
        <DashboardTilesGroup
          :selected-facilities="selectedFacilities"
          :organization-loading="organizationsLoading"
          :monthly-conversions="monthlyConversions"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <CardConversions
          :conversions-data="monthlyConversions"
          :loading="monthlyConversionsLoading"
        />
      </v-col>
      <v-col cols="12" md="6">
        <CardRevenue
          :revenue-data="facilityMonthlyPerformance"
          :loading="monthlySubscriptionsLoading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <CardMonthlyNewSubscriptions
          :monthly-subscriptions="monthlySubscriptions"
          :loading="monthlySubscriptionsLoading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <CardAllActiveSubscriptions
          :all-active-subscriptions="allActiveSubscriptions"
          :loading="allActiveSubscriptionsLoading"
        />
      </v-col>
      <v-col cols="12" md="6">
        <CardMTDNewSubscribers
          :monthly-subscriptions="monthlySubscriptions"
          :loading="monthlySubscriptionsLoading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <CardSubscriptionKudos :selected-facilities="selectedFacilities" />
      </v-col>
      <v-col cols="12" md="6">
        <!-- <CardAverageDurationPerVisit
          :facility-visits="facilityVisits"
          :loading="facilityVisitsLoading"
        /> -->
        <br />
        <!-- <CardAverageNumberOfVisits
          :facility-visits="facilityVisits"
          :loading="facilityVisitsLoading"
        /> -->
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import useDashboard from "../useDashboard";
import DashboardTilesGroup from "./DashboardTilesGroup.vue";
import CardMonthlyNewSubscriptions from "./CardMonthlyNewSubscriptions.vue";
import CardAllActiveSubscriptions from "./CardAllActiveSubscriptions.vue";
import {
  MONTHLY_SUBSCRIPTIONS,
  ALL_ACTIVE_SUBSCRIPTIONS,
  MONTHLY_CONVERSIONS,
  FACILITY_VISITS_REPORT,
  FACILITY_MONTHLY_PERFORMANCE,
} from "./graphql";
import {
  formatToISODate,
  getLast12Months,
  getMonth,
} from "@tod-ui/helpers/datetime";
import CardRevenue from "./CardRevenue.vue";
// import CardAverageNumberOfVisits from "./CardAverageNumberOfVisits.vue";
// import CardAverageDurationPerVisit from "./CardAverageDurationPerVisit.vue";
import CardMTDNewSubscribers from "./CardMTDNewSubscribers.vue";
import CardSubscriptionKudos from "./CardSubscriptionKudos.vue";
import CardConversions from "./CardConversions.vue";
import useUser from "@components/authentication/useUser";
import { roundValue } from "@tod-ui/helpers/math";
import { computed, ref } from "vue";

export default {
  name: "PageManagementSummary",
  components: {
    LayoutPage,
    RegionAndFacilitySelect,
    DashboardTilesGroup,
    CardMonthlyNewSubscriptions,
    CardAllActiveSubscriptions,
    CardRevenue,
    // CardAverageNumberOfVisits,
    // CardAverageDurationPerVisit,
    CardMTDNewSubscribers,
    CardSubscriptionKudos,
    CardConversions,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const { organizationsLoading, selectedFacilities } = useDashboard();
    const defaultOrganizationName = defaultOrganization.value.name;

    const currentMonth = computed(() => {
      return getMonth(0, 0, defaultOrganization.value.timeZone);
    });
    const currentDate = computed(() => {
      const date = new Date().toISOString();
      return formatToISODate(date, defaultOrganization.value.timeZone);
    });

    const dateTime = ref({
      after: currentMonth.value,
      before: currentDate.value,
    });

    return {
      organizationsLoading,
      selectedFacilities,
      defaultOrganization,
      defaultOrganizationName,
      dateTime,
    };
  },
  data() {
    return {
      monthlySubscriptions: [],
      facilityVisits: [],
      allActiveSubscriptions: [],
      monthlyConversions: [],
      facilityMonthlyPerformance: [],
      showRegionFacilitySelectBoxes: true,
    };
  },
  computed: {
    drawerBtn() {
      return this.showRegionFacilitySelectBoxes
        ? "fas fa-chevron-double-up"
        : "fas fa-chevron-double-down";
    },
    loading() {
      return this.organizationsLoading;
    },
    facilityVisitsLoading() {
      return this.$apollo.queries.facilityVisits.loading || this.loading;
    },
    allActiveSubscriptionsLoading() {
      return (
        this.$apollo.queries.allActiveSubscriptions.loading || this.loading
      );
    },
    monthlySubscriptionsLoading() {
      return this.$apollo.queries.monthlySubscriptions.loading || this.loading;
    },
    monthlyConversionsLoading() {
      return this.$apollo.queries.monthlyConversions.loading || this.loading;
    },
    currentDate() {
      const date = new Date().toISOString();
      return formatToISODate(date, this.defaultOrganization.timeZone);
    },
    currentMonth() {
      return getMonth(0, 0, this.defaultOrganization.timeZone);
    },
  },
  apollo: {
    monthlySubscriptions: {
      query: MONTHLY_SUBSCRIPTIONS,
      variables() {
        return {
          ids: this.selectedFacilities.map((f) => f.id),
          month: getLast12Months(null, this.defaultOrganization.timeZone),
        };
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
    facilityMonthlyPerformance: {
      query: FACILITY_MONTHLY_PERFORMANCE,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          month: getLast12Months(null, this.defaultOrganization.timeZone, true),
        };
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
    allActiveSubscriptions: {
      query: ALL_ACTIVE_SUBSCRIPTIONS,
      variables() {
        return {
          ids: this.selectedFacilities.map((f) => f.id),
        };
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
    facilityVisits: {
      query: FACILITY_VISITS_REPORT,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          dateFilter: this.dateTime,
        };
      },
      update({ facilityVisitsReport }) {
        return facilityVisitsReport.map((visit) => ({
          ...visit,
          avgVisitDurationMinute: roundValue(visit.avgVisitDurationMinute),
        }));
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
    monthlyConversions: {
      query: MONTHLY_CONVERSIONS,
      variables() {
        return {
          ids: this.selectedFacilities.map((f) => f.id),
          month: getLast12Months(null, this.defaultOrganization.timeZone),
        };
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
  },
  methods: {
    toggleDrawer() {
      return (this.showRegionFacilitySelectBoxes =
        !this.showRegionFacilitySelectBoxes);
    },
  },
};
</script>
