<template>
  <CardLayoutV2 title="Climate Monitoring" :loading="loading">
    <template #header-actions>
      <div class="d-flex">
        <v-select
          v-model="selectedClimateType"
          class="mr-4"
          :items="climateTypes"
          label="Climate Type"
          dense
          outlined
        />
        <div class="mr-4">
          <v-btn
            class="action-btn mr-2"
            :color="tab === 0 ? 'success' : 'grey lighten-1'"
            @click="tab = 0"
          >
            Default
          </v-btn>
          <v-btn
            class="action-btn"
            :color="tab === 1 ? 'success' : 'grey lighten-1'"
            @click="tab = 1"
          >
            Extensive
          </v-btn>
        </div>
        <div class="mr-4">
          <DateTimeFilter
            v-model="dateTimeFilter"
            :ranges="['Today', '2d', '1w', '1m']"
            default="1m"
            custom
            class="black-border"
          />
        </div>
      </div>
    </template>

    <div v-if="tab === 0">
      <CardClimateMonitoringDefaultView
        :selected-climate-type="selectedClimateType"
        :date-time-filter="dateTimeFilter"
      />
    </div>
    <div v-else>
      <CardClimateMonitoringExtensiveView
        :climate-type="selectedClimateType"
        :date-time-filter="dateTimeFilter"
      />
    </div>
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { ref } from "vue";
import CardClimateMonitoringDefaultView from "./CardClimateMonitoringDefaultView.vue";
import CardClimateMonitoringExtensiveView from "./CardClimateMonitoringExtensiveView.vue";

export default {
  components: {
    CardLayoutV2,
    DateTimeFilter,
    CardClimateMonitoringDefaultView,
    CardClimateMonitoringExtensiveView,
  },
  setup() {
    const loading = ref(false);
    const tab = ref(0);
    const dateTimeFilter = ref({
      before: null,
      after: null,
    });

    const selectedClimateType = ref("TEMPERATURE");
    const climateTypes = [
      { text: "Temperature", value: "TEMPERATURE" },
      { text: "Humidity", value: "HUMIDITY" },
    ];

    return {
      loading,
      tab,
      dateTimeFilter,
      selectedClimateType,
      climateTypes,
    };
  },
};
</script>

<style lang="scss" scoped></style>
