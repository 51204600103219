<template>
  <v-card outlined>
    <div :style="`background-color: ${color}; height: 18px`"></div>
    <v-card-title class="subtitle-1">
      Active Smart Units
      <v-spacer />
      <div style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="activeSmartUnits"
      :loading="loading"
      :search="search"
      :custom-filter="customFilter"
      :custom-sort="customSort"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 500] }"
      :sort-by="['name']"
    >
      <template #item.actions="{ item }">
        <ButtonView title="View Smart Unit" @click="gotoZone(item)" />
      </template>

      <template #item.name="{ item }">
        {{ item.name }}
      </template>
      <template #item.responderFirst="{ item }">
        {{ responderFirstName(item) }}
      </template>

      <template #item.responderLast="{ item }">
        {{ responderLastName(item) }}
      </template>

      <template #item.devices="{ item }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="device in item.devices"
            :key="device.id"
            class="ml-0 pl-0"
          >
            <v-list-item-content>
              {{ device.shortId || device.externalId }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.health="{ item }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="device in item.devices"
            :key="device.id"
            class="ml-0 pl-0"
          >
            <v-list-item-icon>
              <DeviceGaugeCluster :device="device" />
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </template>

      <template #item.temperature="{ item }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="device in item.devices"
            :key="device.id"
            class="mx-0 px-0"
          >
            <div
              v-if="device.temperature"
              :class="`${smartUnitTemperatureColor(device.temperature)}--text`"
            >
              {{ convertCelsiusToFahrenheit(device.temperature) }}℉
            </div>
            <div v-else class="grey--text">N/A</div>
          </v-list-item>
        </v-list>
      </template>
      <template #item.humidity="{ item }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="device in item.devices"
            :key="device.id"
            class="mx-0 px-0"
          >
            <div
              v-if="device.humidity"
              :class="`${smartUnitHumidityColor(device.humidity)}--text`"
            >
              {{ roundValue(device.humidity) }}%
            </div>
            <div v-else class="grey--text">N/A</div>
          </v-list-item>
        </v-list>
      </template>

      <template #item.incident="{ item }">
        <v-btn
          v-if="item.incidents.length > 0"
          color="amber lighten-3"
          small
          title="Resolve Incident"
          @click="gotoIncident(item.incidents[0])"
        >
          <v-icon left small>fas fa-exclamation-triangle</v-icon>
          Resolve
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { activeSmartUnitsDetailed } from "../../graphql/organizations";
import useFacility from "../authentication/useFacility";

import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonView from "@atoms/ButtonView.vue";
import {
  smartUnitHumidityColor,
  smartUnitTemperatureColor,
} from "@components/smart_units/helper";
import { roundValue } from "@tod-ui/helpers/math";
import { convertCelsiusToFahrenheit } from "@tod-ui/helpers/utils";
import { displayUnitType } from "@tod-ui/helpers/unitType";

export default {
  name: "DeviceInfoTable",
  components: {
    DeviceGaugeCluster,
    SearchField,
    ButtonView,
  },
  props: {
    color: {
      type: String,
      default: "#FFF",
    },
  },
  setup() {
    const { currentFacilityId } = useFacility();
    return { currentFacilityId };
  },
  data: () => ({
    activeSmartUnits: [],
    headers: [
      {
        text: "Details",
        align: "start",
        filterable: false,
        sortable: false,
        value: "actions",
      },
      {
        text: "Unit #",
        value: "name",
      },
      {
        text: "Unit Type",
        value: "unitTypeName",
      },
      {
        text: "Unit Size",
        value: "unitTypeSize",
      },
      {
        text: "First Name",
        value: "responderFirst",
      },
      {
        text: "Last Name",
        value: "responderLast",
      },
      {
        text: "Assigned Devices",
        value: "devices",
      },
      {
        text: "Device Status",
        filterable: false,
        sortable: false,
        value: "health",
      },
      { text: "Temperature °F", value: "temperature" },
      { text: "Humidity", value: "humidity" },
      {
        text: "Motion Incident",
        value: "incident",
        sortable: false,
      },
    ],
    search: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.activeSmartUnits.loading;
    },
  },
  apollo: {
    activeSmartUnits: {
      query: activeSmartUnitsDetailed,
      variables() {
        return { id: this.currentFacilityId };
      },
      fetchPolicy: "no-cache",
      update: ({ smartUnits }) =>
        smartUnits
          .filter((o) => o.devices.length > 0)
          .map((unit) => ({
            ...unit,
            unitTypeName: displayUnitType(unit.unitType, "type"),
            unitTypeSize: displayUnitType(unit.unitType, "size"),
          })),
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `organization`
      let _search = search.toLowerCase();

      // Filter on `name`
      if (item.name.toLowerCase().indexOf(_search) >= 0) return true;

      // Filter on `responder`
      const firstName = this.responderFirstName(item);
      if (firstName && firstName.toLowerCase().indexOf(_search) >= 0)
        return true;
      const lastName = this.responderLastName(item);
      if (lastName && lastName.toLowerCase().indexOf(_search) >= 0) return true;

      // Filter on `devices`
      const devices = item.devices
        .map((device) => device.shortId || device.externalId)
        .join(" ")
        .toLowerCase();
      if (devices.indexOf(_search) >= 0) return true;

      // Filters for unit type and size
      if (item.unitTypeName.toLowerCase().indexOf(_search) >= 0) return true;
      if (item.unitTypeSize.toLowerCase().indexOf(_search) >= 0) return true;

      return false;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0];

      items.sort((a, b) => {
        if (_sortBy === "name") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          if (!_sortDesc) return aName < bName ? -1 : 1;
          return bName < aName ? -1 : 1;
        } else if (_sortBy === "responderFirst") {
          const aName = this.responderFirstName(a).toLowerCase();
          const bName = this.responderFirstName(b).toLowerCase();

          if (!_sortDesc) return aName < bName ? -1 : 1;
          return bName < aName ? -1 : 1;
        } else if (_sortBy === "responderLast") {
          const aName = this.responderLastName(a).toLowerCase();
          const bName = this.responderLastName(b).toLowerCase();

          if (!_sortDesc) return aName < bName ? -1 : 1;
          return bName < aName ? -1 : 1;
        } else if (_sortBy === "devices") {
          const aDevices = a.devices
            .map((device) => device.shortId || device.externalId)
            .sort((da, db) => {
              if (!_sortDesc) return da < db ? -1 : 1;
              return db < da ? -1 : 1;
            })
            .join(" ");
          const bDevices = b.devices
            .map((device) => device.shortId || device.externalId)
            .sort((da, db) => {
              if (!_sortDesc) return da < db ? -1 : 1;
              return db < da ? -1 : 1;
            })
            .join(" ");

          if (!_sortDesc) return aDevices < bDevices ? -1 : 1;
          return bDevices < aDevices ? -1 : 1;
        } else if (_sortBy === "unitTypeName" || _sortBy === "unitTypeSize") {
          const aValue = a[_sortBy].toLowerCase();
          const bValue = b[_sortBy].toLowerCase();

          if (!_sortDesc) return aValue < bValue ? -1 : 1;
          return bValue < aValue ? -1 : 1;
        } else {
          // Default sort comparison
          if (!_sortDesc) return a[_sortBy] < b[_sortBy] ? -1 : 1;
          return b[_sortBy] < a[_sortBy] ? -1 : 1;
        }
      });

      return items;
    },
    responderFirstName({ responders }) {
      if (responders.length === 0) return "(error)";

      const responder = responders[0];
      if (responder.user) return responder.user.firstName || "";
    },
    responderLastName({ responders }) {
      if (responders.length === 0) return "";

      const responder = responders[0];
      if (responder.user) return responder.user.lastName || "";
    },
    gotoIncident(incident) {
      this.$router.push({
        name: "Incident",
        params: {
          incident_id: incident.id,
        },
      });
    },
    gotoZone(zone) {
      this.$router.push({
        name: "AssignedSmartUnitDrilldown",
        params: {
          renter_id: zone.responders[0].user.id,
          zone_id: zone.id,
        },
      });
    },
    smartUnitTemperatureColor,
    convertCelsiusToFahrenheit,
    roundValue,
    smartUnitHumidityColor,
  },
};
</script>
