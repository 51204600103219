<template>
  <LayoutPage>
    <DialogLayout
      v-model="isPopupTable"
      title="24 May - Vacant Unit, During After Hours"
      width="1300px"
      :close-on-content-click="false"
      :max-width="1300"
      :max-height="500"
    >
      <PopupTable />
    </DialogLayout>
    <template #title>
      <v-toolbar-title class="headline">
        Three by Three Storage Operations Summary
      </v-toolbar-title>
    </template>
    <FacilitySelect />
    <v-row>
      <v-col>
        <ComparisonTile
          heading="Open Incidents"
          head-value="4"
          top-title="Current"
          top-value="2"
          bottom-title="Overdue"
          bottom-value="2"
          avatar-color="rgb(105,189,217)"
          top-color="green"
          bottom-color="red"
          avatar-name="open"
          @headClickEvent="handleHeadClickEvent"
          @topClickEvent="handleTopClickEvent"
          @bottomClickEvent="handleBottomClickEvent"
        />
      </v-col>
      <v-col>
        <ComparisonTile
          heading="In Progress Incidents"
          head-value="4"
          top-title="In Review"
          top-value="2"
          bottom-title="Overdue"
          bottom-value="2"
          top-color="green"
          bottom-color="red"
          avatar-color="rgba(249, 203, 0, 1)"
          avatar-name="inprogress"
          @headClickEvent="handleHeadClickEvent"
          @topClickEvent="handleTopClickEvent"
          @bottomClickEvent="handleBottomClickEvent"
        />
      </v-col>
      <v-col>
        <ComparisonTile
          heading="Open Tasks"
          head-value="4"
          top-title="In Progress"
          top-value="3"
          bottom-title="Overdue"
          bottom-value="6"
          top-color="green"
          bottom-color="red"
          avatar-color="#D50D41"
          avatar-name="task"
          @headClickEvent="handleHeadClickEvent"
          @topClickEvent="handleTopClickEvent"
          @bottomClickEvent="handleBottomClickEvent"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DashboardLayout title="Smart Traffic">
          <template #header-actions>
            <DateTimeFilter
              v-model="dateRange"
              :ranges="['Today', '1m', '1w']"
              custom
              inverted
              time-zone="America/New_York"
            />
            <div class="mr-4">
              <DemoIncidentFilter />
            </div>
          </template>
          <div style="cursor: pointer">
            <StackedBarChart
              :chart-data="chartData"
              @chartClicked="chartClicked"
            />
          </div>
        </DashboardLayout>
      </v-col>
    </v-row>
    <div class="flex-container mr-4 mb-2">
      <DateTimeFilter
        v-model="dateRange"
        :ranges="['2d', '1w', '1m']"
        custom
        inverted
        time-zone="America/New_York"
      />
    </div>
    <v-row>
      <v-col cols="4">
        <DashboardLayout title="Humidity">
          <v-row>
            <v-col cols="8">
              <p>
                Extensive :
                <v-menu bottom left>
                  <template #activator="{ on }">
                    <v-btn small text v-on="on">Facility 2</v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="facility in facilities" :key="facility">
                      <v-btn text small>{{ facility }}</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </p>
              <p>Frequent :<v-btn small text> 1 Facility</v-btn></p>
            </v-col>
            <v-col col="4">
              <CardThermometer :temperature="76" :time="12" />
            </v-col>
          </v-row>
        </DashboardLayout>
      </v-col>
      <v-col cols="4">
        <DashboardLayout title="Cold Temperature">
          <v-row>
            <v-col cols="8">
              <p>
                Extensive :
                <v-menu bottom left>
                  <template #activator="{ on }">
                    <v-btn small text v-on="on">Facility 2</v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="facility in facilities" :key="facility">
                      <v-btn text small>{{ facility }}</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </p>
              <p>Frequent : <v-btn small text>1 Facility</v-btn></p>
            </v-col>
            <v-col col="4">
              <CardThermometer :temperature="10" :time="27" />
            </v-col>
          </v-row>
        </DashboardLayout>
      </v-col>
      <v-col cols="4">
        <DashboardLayout title="High Temperature">
          <v-row>
            <v-col cols="8">
              <p>
                Extensive :
                <v-menu bottom left>
                  <template #activator="{ on }">
                    <v-btn small text v-on="on">Facility 2</v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="facility in facilities" :key="facility">
                      <v-btn text small>{{ facility }}</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </p>
              <p>Frequent : <v-btn small text>1 Facility</v-btn></p>
            </v-col>
            <v-col col="4">
              <CardThermometer :temperature="91" :time="12" />
            </v-col>
          </v-row>
        </DashboardLayout>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DashboardLayout title="Temperature & Humidity">
          <div class="flex-start">
            <v-select
              v-model="selectedGraphFacility"
              style="max-width: 240px"
              dense
              :items="['Storage Sense', 'Facility A', 'Facility B']"
              label="Select Facility"
              outlined
              item-text="name"
              item-value="id"
            />
            <v-select
              v-model="selectedGraphZone"
              style="max-width: 240px"
              dense
              :items="['Front Gate', 'Back Gate']"
              label="Select Zone"
              outlined
              item-text="name"
              item-value="id"
            />
          </div>
          <div>
            <img
              style="width: 100%"
              src="../../../../public/demochart.png"
              alt="Temperature And Humidity Chart"
            />
            <div class="chart_time">
              <p>03 - Aug</p>
              <p>04 - Aug</p>
              <p>05 - Aug</p>
              <p>06 - Aug</p>
              <p>07 - Aug</p>
              <p>08 - Aug</p>
              <p>09 - Aug</p>
              <p>10 - Aug</p>
              <p>11 - Aug</p>
              <p>12 - Aug</p>
              <p>13 - Aug</p>
              <p>14 - Aug</p>
              <p>15 - Aug</p>
              <p>16 - Aug</p>
              <p>17 - Aug</p>
              <p>18 - Aug</p>
              <p>19 - Aug</p>
              <p>20 - Aug</p>
              <p>21 - Aug</p>
              <p>22 - Aug</p>
              <p>23 - Aug</p>
              <p>24 - Aug</p>
              <p>25 - Aug</p>
              <p>26 - Aug</p>
              <p>27 - Aug</p>
              <p>28 - Aug</p>
              <p>29 - Aug</p>
              <p>30 - Aug</p>
              <p>31 - Aug</p>
              <p>01 - Sept</p>
              <p>02 - Sept</p>
            </div>
          </div>
        </DashboardLayout>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <DashboardLayout title="Move-Out Hygiene Compliance">
          <ComparisonTile
            heading="Vacant Unit Unclean Score"
            head-value="22%"
            top-title="Total Uncleaned Units"
            top-value="2"
            bottom-title="Average Cleaning Days"
            bottom-value="3"
            :is-shadow="false"
            :is-clickable="false"
          />
          <h3 style="text-align: center; margin-top: 20px">
            Move-Out Hygiene Compliance
          </h3>
          <div style="cursor: pointer">
            <PieChart :chart-data="moveOut" />
          </div>
        </DashboardLayout>
      </v-col>
      <v-col cols="6">
        <DashboardLayout title="Ongoing Vacant Unit Hygiene">
          <ComparisonTile
            class="mt-5"
            top-title="Vacant Unit Unclean Score"
            bottom-title="Total Uncleaned Units"
            top-value="22%"
            bottom-value="2"
            :is-shadow="false"
            :is-clickable="false"
          />
          <h3 style="text-align: center; margin-top: 20px" class="mt-15">
            Ongoing Vacant Unit Hygiene
          </h3>
          <div style="cursor: pointer">
            <PieChart :chart-data="onGoing" />
          </div>
        </DashboardLayout>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import useUser from "@components/authentication/useUser";
import ComparisonTile from "../call-center-dashboard/ComparisonTile.vue";
import FacilitySelect from "./FacilitySelect.vue";
import StackedBarChart from "./StackedBarChart.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import CardThermometer from "./CardThermometer";
import PieChart from "../management_dashboard/PieChart";
import PopupTable from "./PopupTable.vue";
import DialogLayout from "../DemoDialogLayout.vue";
import DashboardLayout from "../DashboardLayout.vue";
import DemoIncidentFilter from "../DemoIncidentFilter.vue";

export default {
  name: "PageCallCenterDashboard",
  components: {
    LayoutPage,
    DialogLayout,
    FacilitySelect,
    StackedBarChart,
    DateTimeFilter,
    CardThermometer,
    PieChart,
    PopupTable,
    ComparisonTile,
    DashboardLayout,
    DemoIncidentFilter,
  },
  setup() {
    const { defaultOrganization } = useUser();
    return { defaultOrganization };
  },
  data() {
    return {
      dateRange: { after: undefined, before: undefined },
      selectedGraphFacility: "Storage Sense",
      facilities: ["Facility A", "Facility B"],
      selectedGraphZone: "Front Gate",
      isPopupTable: false,
      moveOut: {
        labels: [
          "Units Cleaned Within 7 Days",
          "Units Uncleaned Within 7 Days",
        ],
        datasets: [
          {
            label: "Move-Out Hygiene Compliance",
            data: [10, 4],
            backgroundColor: ["rgb(119,93,208)", "rgb(255,69,96)"],
          },
        ],
      },
      onGoing: {
        labels: [
          "Unit Visited Within 30 Days",
          "Vacant Not Visited Within 30 Days",
        ],
        datasets: [
          {
            label: "Ongoing Vacant Unit Hygiene",
            data: [10, 4],
            backgroundColor: ["rgb(119,93,208)", "rgb(255,69,96)"],
          },
        ],
      },
      chartData: {
        labels: [
          "Aug 22",
          "Aug 23",
          "Aug 24",
          "Aug 25",
          "Aug 26",
          "Aug 27",
          "Aug 28",
          "Aug 29",
          "Aug 30",
          "Sept 01",
          "Sept 02",
          "Sept 03",
        ],
        datasets: [
          {
            label: "Authorized",
            backgroundColor: "#D50D41",
            data: [10, 3, 1, 10, 3, 12, 3, 9, 5, 5, 14, 5],
          },
          {
            label: "Unauthorized",
            backgroundColor: "#323487",
            data: [2, 2, 5, 6, 4, 3, 2, 4, 6, 7, 2, 3],
          },
          {
            label: "Not Acknowledged",
            backgroundColor: "rgba(10, 199, 60, 1)",
            data: [2, 2, 5, 1, 4, 3, 2, 4, 6, 7, 2, 3],
          },
          {
            label: "After Hour - Vacant",
            backgroundColor: "rgba(221, 223, 223, 1)",
            data: [2, 2, 5, 1, 4, 3, 2, 4, 6, 7, 2, 3],
          },
          {
            label: "After Hour - Occupied",
            backgroundColor: "rgba(84, 87, 222, 1)",
            data: [2, 2, 5, 4, 4, 3, 2, 4, 6, 7, 2, 3],
          },
        ],
      },
    };
  },
  methods: {
    chartClicked(filter) {
      this.isPopupTable = true;
    },
    handleHeadClickEvent(event) {
      if (event === "opentask") {
        this.$router.push({
          name: "ViewTask",
          params: {
            status: event,
          },
        });
      } else {
        this.$router.push({
          name: "OpenIncidence",
          params: {
            status: event,
          },
        });
      }
    },
    handleTopClickEvent(event) {
      if (event.includes("opentask")) {
        this.$router.push({
          name: "ViewTask",
          params: {
            status: "inprogress",
          },
        });
      } else {
        this.$router.push({
          name: "OpenIncidence",
          params: {
            status: event,
          },
        });
      }
    },
    handleBottomClickEvent(event) {
      if (event.includes("opentask")) {
        this.$router.push({
          name: "ViewTask",
          params: {
            status: "overdue",
          },
        });
      } else {
        this.$router.push({
          name: "OpenIncidence",
          params: {
            status: event,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  gap: 10px;
  justify-content: end;
  align-items: center;
  margin-top: 10px;
}
.flex-start {
  display: flex;
  gap: 10px;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
}
.chart_time {
  display: flex;
}
.chart_time p {
  font-size: 12px;
  transform: rotate(-25deg);
}
</style>
