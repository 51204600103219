<template>
  <LayoutPage v-if="!isDashboard" class="px-0" title="Manage Tasks">
    <PageManageTasks :is-dashboard="isDashboard" />
  </LayoutPage>
  <v-card v-else outlined>
    <div :style="`background-color: ${color}; height: 18px`"></div>
    <PageManageTasks :is-dashboard="isDashboard" class="pt-0" />
  </v-card>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import PageManageTasks from "./PageManageTasks.vue";

export default {
  name: "TaskPageSwitch",
  components: {
    LayoutPage,
    PageManageTasks,
  },
  props: {
    color: {
      type: String,
      default: "rgb(105,189,217)",
    },
  },
  data() {
    const isDashboard = window.location.href.includes("dashboard");
    return {
      isDashboard,
    };
  },
};
</script>
