<template>
  <v-icon
    :size="size"
    :class="['color-' + thermometerColor]"
    :color="thermometerColor"
  >
    {{ thermometerIcon }}
  </v-icon>
</template>

<script>
export default {
  name: "IconThermometer",
  props: {
    size: {
      type: [Number, String],
      default: "",
    },
    temperatureInCelsius: {
      type: [Number, String],
      default: 0,
    },
    lowThresholdInCelsius: {
      type: [Number, String],
      default: 0,
    },
    highThresholdInCelsius: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    thermometerColor() {
      if (this.temperatureInCelsius < this.lowThresholdInCelsius) {
        return "blue lighten-3";
      } else if (this.temperatureInCelsius > this.highThresholdInCelsius) {
        return "error";
      }
      return "success";
    },
    thermometerIcon() {
      if (this.temperatureInCelsius < this.lowThresholdInCelsius) {
        return "fas fa-temperature-frigid";
      } else if (this.temperatureInCelsius > this.highThresholdInCelsius) {
        return "fas fa-temperature-hot";
      }
      return "fas fa-thermometer-half";
    },
  },
};
</script>
