import { ref, computed } from "vue";
import useFacility from "../authentication/useFacility";
import { formatIncidentType, incidentStatus } from "../common/helpers/incident";
import { formatDeviceName } from "../common/helpers/devices";
import { durationInWords, utcNow } from "@tod-ui/helpers/datetime";
import { apolloWatchQuery } from "../../apollo-client/helper";
import { GET_INCIDENT, CREATE_INCIDENT_SURVEY } from "./graphql";

const incident = ref(null);
const loading = ref(true);

const incidentId = computed(() => incident.value?.id);

const incidentTitle = computed(() =>
  incident.value?.type
    ? [
        `${incident.value.typeName} Incident`,
        `${incident.value.organization?.name} @ ${incident.value.startedAt}`,
      ]
    : ["... Incident", "..."]
);

let observableQuery = null;

function cleanupIncidentRef() {
  incident.value = null;
  loading.value = true;
  observableQuery = null;
}

export default function useIncident() {
  const { facilityFormatDateTime4Humans } = useFacility();

  function fetchIncident(apolloClient, incidentId) {
    observableQuery = apolloWatchQuery(
      apolloClient,
      {
        query: GET_INCIDENT,
        variables: { id: incidentId },
      },
      (data, queryLoading) => {
        if (data?.incident) {
          const survey = JSON.parse(data.incident.survey);
          incident.value = {
            ...data.incident,
            facilityName: data.incident.alerting_strategy.facility_org.name,
            zoneName: data.incident.alerting_strategy.device_org.name,
            incidentDisposition: data.incident.disposition,
            startedAt: facilityFormatDateTime4Humans(data.incident.startedAt),
            incidentStartedAt: data.incident.startedAt,
            closedAt: facilityFormatDateTime4Humans(data.incident.closedAt),
            dueAt:
              data.incident.tasks?.length > 0
                ? facilityFormatDateTime4Humans(data.incident.tasks[0]?.dueAt)
                : "NA",
            duration: durationInWords(
              data.incident.startedAt,
              data.incident.lastUntriggeredAt ||
                data.incident.closedAt ||
                data.incident.resolvedAt
            ),
            resolvedAt: facilityFormatDateTime4Humans(data.incident.resolvedAt),
            statusName: incidentStatus(data.incident),
            typeName: formatIncidentType(data.incident),
            responder: firstResponder(data.incident),
            //TODO: move this logic to backend
            surveyed:
              data.incident.resolution === "surveyed" &&
              survey &&
              survey?.user &&
              survey?.insertedAt,
            needsSurvey:
              (["closed", "muted"].includes(data.incident.status) &&
                (data.incident.resolution === "denied" ||
                  (data.incident.hasDisabledLienResponder &&
                    data.incident.status === "closed"))) ||
              (data.incident.queuedUntilDawn &&
                data.incident.resolution === "unexplained"),
            devices: data.incident.devices
              ? data.incident.devices.map((d) => ({
                  id: d.id,
                  name: formatDeviceName(d),
                }))
              : [],
            survey: {
              ...survey,
              insertedAt: facilityFormatDateTime4Humans(survey?.insertedAt),
            },
          };
        }
        loading.value = queryLoading;
      }
    );
    return observableQuery;
  }

  async function submitIncidentSurvey(dollarApollo, survey) {
    // This mutation goes through the Message Processor's API via apolloMPClient
    const response = await dollarApollo.provider.clients.apolloMPClient.mutate({
      mutation: CREATE_INCIDENT_SURVEY,
      variables: {
        incidentId: incidentId.value,
        organizationId: incident.value.organization.id,
        type: incident.value.type,
        survey: JSON.stringify({
          vacantUnitSurveyReason: survey.vacantUnitSurveyReason,
          insertedAt: utcNow(),
          authorizationStatus: survey.authorizationStatus,
        }),
      },
    });

    if (observableQuery) {
      await observableQuery.refetch();
    }
    return response?.data?.createIncidentSurvey;
  }

  return {
    incident,
    incidentId,
    incidentTitle,
    loading,
    fetchIncident,
    submitIncidentSurvey,
    cleanupIncidentRef,
  };
}

function firstResponder(incident) {
  const sorted = incident.responders.sort(
    (a, b) => a.escalationLevel - b.escalationLevel
  );
  if (!incident.isVacant) {
    return sorted[0];
  }

  return {
    ...sorted[0],
    name: "(Vacant)",
  };
}
